import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './inquire.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';
import emailjs from '@emailjs/browser';

const serviceId = 'service_pgjkx4l';
const templateId = 'template_jzrnsif';
const publicKey = 'pHxdDTW2WnQzihtvD';

function Inquire(): ReactElement {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [disabled, setDisabled] = useState(false);

  async function onSend() {
    try {
      setDisabled(true);
      await emailjs.send(
        serviceId,
        templateId,
        {
          name,
          email,
          title,
          content,
        },
        publicKey,
      );
      alert('이메일 전송 성공!');
      window.location.reload();
    } catch (error) {
      alert(error);
    } finally {
      setDisabled(false);
    }
  }

  return (
    <>
      <Header menuIndex={4} />

      <Styled.Container>
        <Styled.TitleContainer>
          <Common.SizedBoxH height={120} />
          <Typo.Pretendard_ExtraBold fontSize={50} color={Colors.black}>
            문의/상담
          </Typo.Pretendard_ExtraBold>
          <Common.SizedBoxH height={14} />
          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            상담내용을 보내주세요.
          </Typo.Pretendard_Regular>
        </Styled.TitleContainer>

        <Styled.Contents>
          <Common.SizedBox width={1160} height={2}>
            <Common.Fill color={Colors.gray300} />
          </Common.SizedBox>

          <Common.SizedBoxH height={67} />

          <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
            <Typo.Pretendard_ExtraBold fontSize={26} color={Colors.gray300}>
              이름
            </Typo.Pretendard_ExtraBold>
            <Styled.Input value={name} onChange={(e) => setName(e.target.value)} />
          </Common.FlexRow>

          <Common.SizedBoxH height={56} />

          <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
            <Typo.Pretendard_ExtraBold fontSize={26} color={Colors.gray300}>
              이메일
            </Typo.Pretendard_ExtraBold>
            <Styled.Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
          </Common.FlexRow>

          <Common.SizedBoxH height={56} />

          <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
            <Typo.Pretendard_ExtraBold fontSize={26} color={Colors.gray300}>
              제목
            </Typo.Pretendard_ExtraBold>
            <Styled.Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Common.FlexRow>

          <Common.SizedBoxH height={56} />

          <Common.FlexRow width={1160} alignItems="flex-start" justifyContent="space-between">
            <Typo.Pretendard_ExtraBold fontSize={26} color={Colors.gray300}>
              내용
            </Typo.Pretendard_ExtraBold>
            <Styled.TextArea value={content} onChange={(e) => setContent(e.target.value)} />
          </Common.FlexRow>

          <Common.SizedBoxH height={56} />

          <Styled.SendButton onClick={() => onSend()}>
            <Typo.Pretendard_ExtraBold fontSize={30} color={Colors.gray300}>
              보내기
            </Typo.Pretendard_ExtraBold>
          </Styled.SendButton>
        </Styled.Contents>
      </Styled.Container>
    </>
  );
}

export default Inquire;
