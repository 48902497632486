import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomBalloonImage from 'img/photos/disease/0/SymptomBalloon.svg';
import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import BackgroundImage from 'img/photos/aracare/3/background.png';
import CauseContainerImage from 'img/photos/disease/4/cause_container.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 104px 0 95px;
`;

export const StepContainer = styled.div`
  width: 177px;
  height: 200px;

  border-radius: 10px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepIconContainer = styled.div`
  width: 80px;
  height: 50px;

  border-radius: 4px;
  background: var(--ebe-2-cc, #ebe2cc);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(180deg, rgba(235, 226, 204, 0.2) 0%, rgba(235, 226, 204, 0.02) 100%);
  padding: 102px 0 223px;
`;

export const BeforeAfterContainer = styled.div<{ backgroundImage: '*.png' }>`
  width: 374px;
  height: 374px;

  background-image: url(${(p) => p.backgroundImage});

  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  padding: 23px 32px;
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 400px;

  background-color: ${Colors.gold100};
`;

export const Content3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;

  padding: 52px 0 72px;

  background-image: url(${BackgroundImage});
  background-size: cover;
`;

export const PointContainer = styled.div`
  width: 177px;
  height: 177px;

  border-radius: 10px;
  background: #fff;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PointIndexContainer = styled.div`
  width: 93px;
  height: 93px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -40px;
  top: -47px;

  z-index: 1;
`;

export const Content4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 108px 0 100px;
`;

export const CauseConatiner = styled.div`
  width: 794px;
  min-height: 319px;

  background-image: url(${CauseContainerImage});
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  transform: translateX(10px);

  padding: 91px 123px 130px 101px;
`;

export const CheckContainer = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid var(--665-e-4-b, #665e4b);

  position: relative;
`;

export const CheckImageContainer = styled.img`
  width: 35px;
  height: 34px;
  position: absolute;
  right: -7px;
  top: -17px;
`;

export const CauseDash = styled.div`
  width: 100%;
  height: 0px;
  border: 0.5px dashed ${Colors.black};
`;
