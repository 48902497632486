import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './pregnant1.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { WindowContext } from 'context/windowContext';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

// images
import ContraceptionPhoto from 'img/photos/pregnant/1/contraception.png';

import PercentFrameImage0 from 'img/photos/pregnant/1/percent_frames/0.png';
import PercentFrameImage1 from 'img/photos/pregnant/1/percent_frames/1.png';
import PercentFrameImage2 from 'img/photos/pregnant/1/percent_frames/2.png';
import PercentFrameImage3 from 'img/photos/pregnant/1/percent_frames/3.png';
import PercentFrameImage4 from 'img/photos/pregnant/1/percent_frames/4.png';
import PercentFrameImage5 from 'img/photos/pregnant/1/percent_frames/5.png';

const CheckUpDatas = [
  {
    title: '자연피임법',
    datas: ['월경주기조절법', '질외사정법', '체온측정법'],
    frame: PercentFrameImage0,
    color: '#D2BE8C',
    percent: 77,
  },
  {
    title: '차단법',
    datas: ['콘돔'],
    frame: PercentFrameImage1,
    color: '#C69828',
    percent: 80,
  },
  {
    title: '사후피임약',
    datas: ['노레보', '엘라원', '포스티노'],
    frame: PercentFrameImage2,
    color: '#56A01C',
    percent: 95,
  },
  {
    title: '경구피임약',
    datas: ['야즈', '야스민', '클래라'],
    frame: PercentFrameImage3,
    color: '#616D98',
    percent: 98,
  },
  {
    title: '자궁내장치',
    datas: ['미레나', '카일리나', '노바티'],
    frame: PercentFrameImage4,
    color: '#98618F',
    percent: 99,
  },
  {
    title: '피하주사법',
    datas: ['사야나'],
    frame: PercentFrameImage5,
    color: '#986161',
    percent: 99,
  },
];

import ContraceptionTypeImage0 from 'img/photos/pregnant/1/contraception_types/0.png';
import ContraceptionTypeImage1 from 'img/photos/pregnant/1/contraception_types/1.png';
import ContraceptionTypeImage2 from 'img/photos/pregnant/1/contraception_types/2.png';
import ContraceptionTypeImage3 from 'img/photos/pregnant/1/contraception_types/3.png';
import ContraceptionTypeImage4 from 'img/photos/pregnant/1/contraception_types/4.png';
import ContraceptionTypeImage5 from 'img/photos/pregnant/1/contraception_types/5.png';

const ContraceptionTypeDatas = [
  {
    image: ContraceptionTypeImage0,
    title: '경구피임약',
    description:
      '매일 일정 시간에 약을 복용하는 방식으로 피임 효과를 나타내는 방법입니다.\n피임은 물론 생리통 완화, 생리주기 조절 등 다양한 목적으로 복용 하는 사람들이 많습니다.',
    recommand: '간편한 방식의 피임을 원하는 여성성',
  },
  {
    image: ContraceptionTypeImage1,
    title: '사후피임약',
    description:
      '피임을 하지 못하고 성관계를 가진 경우 응급으로 진행하는 방법입니다.\n80~90%의 피임 효과를 나타냅니다.\n성관계 후 72시간 안에 복용하는 것이 좋으며, 빨리 복용할 수록 더욱 효과적입니다.',
    recommand: '피임을 하지 못하고 무방비로 성관계를 가진 여성',
  },
  {
    image: ContraceptionTypeImage2,
    title: '임플라논',
    description:
      '팔에 약 4cm의 막대 모양 물체를 삽입하는 피임 기구입니다.\n막대가 소량의 배란을 억제하는 호르몬을 지속적으로 방출해 피임 효과를 나타냅니다.\n시술 시간은 1분 미만으로 간편하며, 3년간 피임 효과를 나타냅니다.',
    recommand: '자궁 내에 삽입하는 피임 장치가 부담스러운 여성',
  },
  {
    image: ContraceptionTypeImage3,
    title: '미레나',
    description:
      '여성의 자궁 안에 삽입하는 피임 기구입니다.\n매일 적정량의 호르몬을 방출해 수정란이 착상되는 것을 막습니다.\n최대 5년간 99%의 피임 효과를 나타내며, 생리통 또는 생리혈 감소 목적으로 사용하기도 합니다.',
    recommand: '유지 기간이 길고 확실한 피임 효과를 원하는 여성',
  },
  {
    image: ContraceptionTypeImage4,
    title: '카일리나',
    description:
      '여성의 자궁 안에 삽입하는 피임 기구입니다.\n미레나와 동일하게 수정란이 착상되는 것을 막는 호르몬이 매일 일정량 방출됩니다.\n그러나 미레나보다 크기가 작고, 더 낮은 호르몬을 방출하여 시술 부담이 적습니다.\n최대 5년간 피임 효과를 나타납니다.',
    recommand: '시술 부담이 적은 피임 기구를 원하는 여성',
  },
  {
    image: ContraceptionTypeImage5,
    title: '노바티',
    description:
      '작은 기구를 여성의 자궁 안에 삽입하는 구리형 피임 기구입니다.\n자궁에 이물 반응을 유도해 수정란의 착상을 방지합니다.\n간, 심질환, 유방암 등의 질환으로 호르몬 작용을 이용한 피임 기구 사용이 어려운 경우에\n선택 가능한 피임 방법입니다.',
    recommand: '호르몬 작용을 이용한 피임 장치 사용이 어려운 여성',
  },
];

const CautionDatas = [
  {
    title: '경구 피임약',
    content: [
      '경구 피임약은 생리 첫날을 기준으로 5일 안에 복용을 시작하며 21일간 복용 후 7일간 휴약을 합니다.',
      '7일간 약을 먹지 않는 휴약 기간 동안 생리가 시작되며 휴약 기간이 지나면 곧 바로 다음 달 피임약을 복용 합니다.',
    ],
  },
  {
    title: '사후 피임약',
    content: [
      '사후 피임약은 관계 후 바로 먹는 것이 가장 좋은며, 최대 5일 이내에 복용해야 임신을 방지할 수 있습니다.',
      '사후 피임약은 고용량의 호르몬을 투약하는 것이기 때문에 복용 시 출혈, 구토, 생리불순 등이 동반될 수 있습니다.',
    ],
  },
  {
    title: '임플라논, 미레나, 카일리나',
    content: ['임플라논, 미레나, 카일리나 삽입 후 3-6개월 간 불규칙한 출혈이 있을 수 있습니다.'],
  },
];

import CheckMark from 'img/photos/pregnant/1/CheckMark.svg';

function Pregnant1(): ReactElement {
  const menuIndex = 1;
  const subIndex = 1;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Contraception
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            피임
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Styled.MarkText fontSize={20} color={Colors.gray200}>
            피임이란 원치않는 임신으로부터 <mark>자신을 보호하는 방법</mark>입니다.
            <br />
            정확한 상담을 통해 자신에게 맞는 안전한 피임법을 찾아드립니다.
          </Styled.MarkText>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={ContraceptionPhoto} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    피임법은 종류가 다양하고 피임법마다 장단점이 있고, <br />
                    아직 100% 안전하고 부작용이 없는 피임법은 없습니다. <br />
                    다만, 원치않는 임신을 예방하기 위해서는 부작용,
                    <br /> 피임실패율, 비용 등을 고려한 나에게 맞는 피임법을 <br />
                    선택해야 합니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            피임법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow width={1160} alignItems="flex-start" justifyContent="space-between">
            {CheckUpDatas.map((e, i) => (
              <Common.FlexColumn alignItems="center" key={e.title}>
                <Styled.Percent>
                  <Styled.PercentFrame src={e.frame} />
                  <Styled.PercentViewport>
                    <Styled.PercentText fontSize={50} color={Colors.black}>
                      {e.percent}
                      <mark>%</mark>
                    </Styled.PercentText>
                  </Styled.PercentViewport>
                </Styled.Percent>

                <Common.SizedBoxH height={23} />

                <Styled.CheckUpTitle color={e.color}>
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.white}>
                    {e.title}
                  </Typo.Pretendard_Regular>
                </Styled.CheckUpTitle>

                <Common.SizedBoxH height={20} />

                {e.datas.map((e, i) => (
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                    {e}
                  </Typo.Pretendard_Regular>
                ))}
              </Common.FlexColumn>
            ))}
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            피임의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          {ContraceptionTypeDatas.map((e, i) => (
            <Common.FlexColumn key={e.title} width={1160} alignItems="stretch">
              <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
                0{i + 1}
              </Typo.BodoniMT_BoldItalic>
              <Common.SizedBox width={1160} height={2}>
                <Common.Fill color={Colors.gray200} />
              </Common.SizedBox>

              <Common.SizedBoxH height={32} />

              <Common.FlexRow justifyContent="flex-start">
                <Common.SizedBoxW width={30} />
                <Common.SizedImage width={373} height={250} src={e.image} objectFit="contain" />
                <Common.SizedBoxW width={39} />

                <Common.FlexColumn alignItems="flex-start">
                  <Common.SizedBoxH height={40} />

                  <Typo.Pretendard_Medium fontSize={30} color={Colors.gray200}>
                    {e.title}
                  </Typo.Pretendard_Medium>

                  <Common.SizedBoxH height={20} />

                  <Typo.Pretendard_Regular lineHeight={30} textAlign="left" fontSize={20} color={Colors.gray200}>
                    {e.description}
                  </Typo.Pretendard_Regular>

                  <Common.SizedBoxH height={20} />

                  <Common.FlexRow alignItems="center">
                    <Styled.RecommandBox>
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gold300}>
                        추천대상
                      </Typo.Pretendard_Regular>
                    </Styled.RecommandBox>
                    <Common.SizedBoxW width={20} />
                    <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                      {e.recommand}
                    </Typo.Pretendard_Regular>
                  </Common.FlexRow>
                </Common.FlexColumn>
              </Common.FlexRow>

              <Common.SizedBoxH height={50} />
            </Common.FlexColumn>
          ))}
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Caution
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            피임 주의사항
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={112} />

          {CautionDatas.map((e, i) => (
            <>
              <Common.SizedBoxH height={30} />
              <Styled.CautionBox key={e.title}>
                <Common.SizedBox width={70} height={10}>
                  <Common.Fill color={Colors.gold200} />
                </Common.SizedBox>

                <Typo.Pretendard_Medium verticalAlign="top" textAlign="left" color={Colors.gray200} fontSize={30}>
                  {e.title}
                </Typo.Pretendard_Medium>

                <Common.SizedBoxH height={19} />

                {e.content.map((e, i) => (
                  <div key={e}>
                    <Common.SizedBoxH height={10} />
                    <Common.FlexRow alignItems="center">
                      <Common.SizedImage width={30} height={30} src={CheckMark} />
                      <Common.SizedBoxW width={9} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        {e}
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </div>
                ))}
              </Styled.CautionBox>
            </>
          ))}
        </Styled.Content2>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Pregnant1;
