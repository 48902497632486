import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 100px;
`;

export const CheckUpButton = styled.button`
  width: 130px;
  height: 50px;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Pretendard;
  font-weight: 400;
  text-align: center;
  font-size: 20px;

  background-color: ${Colors.white};
  color: ${Colors.gold300};

  border: 1px solid var(--ce-9715, #ce9715);
  :disabled {
    background-color: ${Colors.gold300};
    color: ${Colors.white};
  }
`;

export const CheckUpContainer = styled.div`
  width: 472px;
  height: 340px;

  position: relative;

  overflow: hidden;
`;

export const CheckUpImageText = styled.div`
  position: absolute;

  left: 30px;
  bottom: 26px;
`;

export const CheckUpImageContainer = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 472px;
  height: 340px;

  object-fit: contain;
`;
