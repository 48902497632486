import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

import ServiceBackgroundImage from 'img/main/service_background.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;
`;

export const MainViewport = styled.div<{ width: number; height: number }>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

export const MainVideo = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainViedoTextArea = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Solutions = styled.div`
  width: 100%;
  height: 929px;
  padding: 120px 20.5px;

  background-color: ${Colors.white};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SolutionCard = styled.div`
  width: 275px;
  height: 500px;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

export const SolutionCardDescription = styled.div`
  width: 100%;
  height: 180px;

  padding: 25px 20px 30px;

  background-color: ${Colors.gray100};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Services = styled.div`
  width: 100%;
  height: 645px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(${ServiceBackgroundImage});
  background-size: 100% 100% cover;
`;

export const ServiceCard = styled.div`
  width: 373px;
  height: 213px;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px 0px;

  padding: 33px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 1000;
`;
