import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease8.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/8/banner.png';
import StepImage from 'img/photos/disease/8/step_image.png';

import SymptomArrow from 'img/photos/disease/Arrow.svg';
import DownArrow from 'img/photos/disease/DownArrow.png';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';
import TreatmentImage2 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '인유두종 바이러스',
    description:
      '자궁경부암은 성관계에 의한 인유두종 바이러스 감염이 가장 큰 원인으\n로 성관계 파트너가 많거나 성관계를 일찍 시작하였을 경우 발병 가능성\n이 높아집니다.',
    image: TreatmentImage0,
  },
  {
    title: '예방접종',
    description:
      '가다실 4가, 9가 예방 접종을 통해서 자궁경부암을 미리 예방 할 수 있습니다.\n국가에서 청소년 대상으로 가다실 4가를 무료 예방접종을 지원합니다.',
    image: TreatmentImage1,
  },
  {
    title: '몸의 이상 변화 확인',
    description:
      '성관계 뒤 출혈, 폐경 여성의 출혈, 질 분비물 증가 및 악취 등 몸에 나\n타나는 이상 변화가 나타나면 방치하지 말고 산부인과에 내원하여 검\n사를 받습니다.',
    image: TreatmentImage2,
  },
];

function Disease8(): ReactElement {
  const menuIndex = 2;
  const subIndex = 8;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cervical Cancer
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            자궁경부암
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            자궁의 아래쪽에 위치한 자궁경부에 생기는 암으로서 우리나라에서
            <br />
            발생하는 부인과적 암질환 중에서 가장 많이 나타나고 있습니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    자궁 경부에 암이 발생한 경우를 말합니다.
                    <br />
                    발생원인 대부분이 성관계를 통한 인두유종 바이러스 감염입니다. 인두유종 바이러스에 감염되었을 경우 특별한 증상이 없고,
                    시간이 지나면 자연적으로 소실되는데 극히 소수에서만 이 바이러스가 상피 세포를 변형시켜 암으로 발전하게 됩니다.{' '}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>

          <Common.SizedBoxH height={557} />

          <Styled.StepContainer>
            <Styled.StepImageContainer src={StepImage} />
            <Styled.StepViewport>
              <Common.SizedBoxH height={64} />
              <Styled.StepText>
                자궁경부암은 암이 되기 이전 단계인 전암단계를 상당 기간 거치는 것이 특징입니다.
                <br />
                <mark>
                  이형성증 {'>'} 상피내암 {'>'} 자궁경부암 순서
                </mark>
                로 평균 7~10년에 걸쳐 진행됩니다.
                <br />
                단계가 높아질 수록 자궁경부암으로 발전될 가능성이 높아 평소 정기 검진이 필수적입니다.
              </Styled.StepText>
              <Common.SizedBoxH height={17} />
              <Styled.StepBar>
                <Typo.Pretendard_Regular width={170} fontSize={20} color={Colors.white}>
                  정상
                </Typo.Pretendard_Regular>
                <Typo.Pretendard_Regular width={170} fontSize={20} color={Colors.white}>
                  경증이형성증
                </Typo.Pretendard_Regular>
                <Typo.Pretendard_Regular width={170} fontSize={20} color={Colors.white}>
                  중등도이형성증
                </Typo.Pretendard_Regular>
                <Typo.Pretendard_Regular width={170} fontSize={20} color={Colors.white}>
                  중증이형성증
                </Typo.Pretendard_Regular>
                <Common.FlexRow width={170} alignItems="flex-end" justifyContent="center">
                  <Typo.Pretendard_Regular verticalAlign="bottom" fontSize={20} color={Colors.white}>
                    상피내암
                  </Typo.Pretendard_Regular>
                  <Typo.Pretendard_Regular verticalAlign="bottom" fontSize={10} color={Colors.white}>
                    (0기암)
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
                <Typo.Pretendard_Regular width={170} fontSize={20} color={Colors.white}>
                  자궁경부암
                </Typo.Pretendard_Regular>
              </Styled.StepBar>

              <Common.SizedBoxH height={31} />

              <Common.Dash width={964} height={0} color={Colors.gray300} />

              <Common.SizedBoxH height={15} />

              <Common.FlexRow width={910} alignItems="center" justifyContent="space-between">
                <Typo.Pretendard_Regular color={Colors.gray300} fontSize={20}>
                  정상
                </Typo.Pretendard_Regular>
                <Typo.Pretendard_Regular color={'#9C7C2E'} fontSize={20}>
                  저등급병변
                </Typo.Pretendard_Regular>
                <Typo.Pretendard_Regular color={'#FBDC93'} fontSize={20}>
                  고등급병변
                </Typo.Pretendard_Regular>
                <Typo.Pretendard_Regular color={'#824C3A'} fontSize={20}>
                  암
                </Typo.Pretendard_Regular>
              </Common.FlexRow>
            </Styled.StepViewport>
          </Styled.StepContainer>

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            자궁경부암 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={44} />
                <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={20} color={Colors.gray200}>
                  자궁경부암은 초기에는 증상이 거의 없으며, 어느 정도 진행이 된 후 증상이 발생합니다.
                  <br />
                  아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.{' '}
                </Typo.Pretendard_Regular>
                <Common.SizedBoxH height={55} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        질 분비물 과다 및 악취
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        배뇨 장애
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        체중 감소
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        4.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        성관계 후 출혈
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            아라산부인과 자궁경부암 검사
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={100} height={100} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={20} lineHeight={30} color={Colors.gray300}>
            자궁경부암은 자궁 경부의 세포를 면봉이나 칫솔 모양의 특수 기구로 살짝 긁어내는 액상 세포 검사를 진행합니다.
            <br />
            약 2분 이내면 완료되는 간단한 검사로 통증이나 질 내 손상이 없습니다.
            <br />
            성경험이 있는 만 20세 여성의 경우 국민건강보험에 가입되었다면
            <br />
            누구나 2년에 한 번씩 무료 자궁경부암 검진이 가능합니다.
          </Typo.Pretendard_Regular>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Precaution
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            자궁경부암 예방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={23} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content2>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease8;
