import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 47px;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 98px 0 40px;

  background-color: ${Colors.gold100};
`;

export const SolutionCard = styled.div`
  width: 964px;

  border-radius: 20px;
  background: var(--ffffff, #fff);

  position: relative;
`;

export const SolutionCardViewport = styled.div`
  border-radius: 20px;

  display: flex;

  width: 100%;
  overflow: hidden;
`;

export const SolutionIconContainer = styled.div`
  width: 295px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 60px 0 44px;

  background: var(--radial, radial-gradient(50% 50% at 50% 50%, rgba(235, 226, 204, 0) 0%, #ebe2cc 100%));
`;

export const SolutionIndexContainer = styled.div`
  width: 93px;
  height: 93px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -53px;
  top: -30px;

  z-index: 1;
`;
