import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  white-space: pre-line;

  padding: 93px 0 100px;
`;

export const TitleText = styled(Typo.Pretendard_Light)`
  color: ${Colors.black};
  font-size: 50px;

  mark {
    font-weight: 700;
    color: ${Colors.black};
    background: none;
  }
`;

export const Table = styled.table`
  width: 1160px;

  border-collapse: collapse;

  border: 1px solid #d9d9d9;

  th {
    color: var(--333333, #333);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    background-color: ${Colors.gold100};

    border: 1px solid #d9d9d9;
  }

  td {
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    border: 1px solid #d9d9d9;

    height: 35px;
  }
`;
