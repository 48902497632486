import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import CauseContainerImage from 'img/photos/disease/4/cause_container.png';
import BackgroundImage from 'img/photos/disease/4/background.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 100px;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 50px 0 142px;

  background-color: ${Colors.gold100};
`;

export const CauseConatiner = styled.div`
  width: 794px;
  min-height: 319px;

  background-image: url(${CauseContainerImage});
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  transform: translateX(10px);

  padding: 91px 123px 130px 101px;
`;

export const CheckContainer = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid var(--665-e-4-b, #665e4b);

  position: relative;
`;

export const CheckImageContainer = styled.img`
  width: 35px;
  height: 34px;
  position: absolute;
  right: -7px;
  top: -17px;
`;

export const CauseDash = styled.div`
  width: 100%;
  height: 0px;
  border: 0.5px dashed ${Colors.black};
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 101px;
`;

export const WhyContainer = styled.div`
  width: 373px;
  height: 373px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(180deg, rgba(206, 151, 21, 0.1) 0%, rgba(155, 116, 22, 0) 50%, rgba(206, 151, 21, 0.1) 100%), #ebe2cc;
`;

export const WhyViewport = styled.div`
  width: 289px;
  height: 289px;

  padding: 8px 11px;

  display: flex;
  flex-direction: column;

  border-radius: 0px 0px 0px 100px;
  border: 1px solid #fff;
`;

export const WhyImageContainer = styled.img<{ top: number; left: number }>`
  position: absolute;
  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
`;

export const SymptomBalloon = styled.div`
  width: 80px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background: var(--ebe-2-cc, #ebe2cc);
`;

export const SymptomContainer = styled.div`
  width: 865px;
  min-height: 138px;

  border-radius: 10px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  background-image: url(${SymptomContainerImage});
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px 25px;
`;

export const Content3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 58px 0 116px;

  background-image: url(${BackgroundImage});
  background-size: cover;
`;

export const Content4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 95px 0 80px;

  background-color: ${Colors.gold100};
`;

export const TreatmentContainer = styled.div`
  width: 964px;
  height: 200px;

  position: relative;

  border-radius: 20px;
  background-color: ${Colors.white};
`;

export const TreatmentContainerViewport = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 964px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;

  display: flex;
`;

export const TreatmentIndexContainer = styled.div`
  width: 93px;
  height: 93px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -53px;
  top: -30px;

  z-index: 1;
`;
