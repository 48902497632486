import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './title.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { WindowContext } from 'context/windowContext';
import { Colors } from 'common';

import AraClinicText from 'img/title/title_text.png';

import { HeaderMenuDatas, TitleDatas } from 'common/datas';

function Title(props: { menuIndex: number; subMenuIndex: number }): ReactElement {
  const { menuIndex, subMenuIndex } = props;

  return (
    <Styled.Container>
      <Styled.TextContainer>
        <Typo.Pretendard_Light fontSize={30} color={Colors.black}>
          {TitleDatas[menuIndex][subMenuIndex].headline}
        </Typo.Pretendard_Light>
        <Common.SizedBoxH height={23} />
        <Typo.Pretendard_ExtraBold fontSize={50} color={Colors.black}>
          {HeaderMenuDatas[menuIndex].sub![subMenuIndex].title}
        </Typo.Pretendard_ExtraBold>
        <Common.SizedBoxH height={30} />
        <Typo.Pretendard_Light textAlign="left" fontSize={20} color={Colors.gray300}>
          {TitleDatas[menuIndex][subMenuIndex].description}
        </Typo.Pretendard_Light>
        <Common.SizedBoxH height={12} />
        <Common.SizedImage src={AraClinicText} width={270} height={17} />
      </Styled.TextContainer>

      <Styled.ImageContainer src={TitleDatas[menuIndex][subMenuIndex].image} />
    </Styled.Container>
  );
}

export default Title;
