import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import * as Styled from './header.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages } from 'common/images';
import { Link } from 'react-router-dom';
import { Colors } from 'common';
import { HeaderMenuDatas } from 'common/datas';
import { WindowContext } from 'context/windowContext';

function Header(props?: { backgroundColor?: string; color?: string; menuIndex?: number; subMenuIndex?: number }): ReactElement {
  const [backgroundColor, setBackgroundColor] = useState(Colors.white);
  const [color, setColor] = useState(Colors.black);

  const [mouseOnHeader, setMouseOnHeader] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [showSelectSubMenu, setShowSelectSubMenu] = useState(false);

  const { scrollPosition } = useContext(WindowContext);

  useEffect(() => {
    if (mouseOnHeader || showSelectSubMenu) {
      setColor(Colors.black);
      setBackgroundColor(Colors.white);
    } else {
      if (props?.backgroundColor) setBackgroundColor(props.backgroundColor);
      if (props?.color) setColor(props.color);
    }
  }, [showSelectSubMenu, mouseOnHeader, props]);

  return (
    <>
      <Styled.Header
        onMouseEnter={(e) => setMouseOnHeader(true)}
        onMouseLeave={(e) => setMouseOnHeader(false)}
        backgroundColor={backgroundColor}
      >
        <Styled.LogoViewport>
          <Link to="/">
            <Common.SizedImage
              src={color === Colors.white ? CommonImages.LogoWhite : CommonImages.Logo}
              width={225}
              height={37}
              objectFit="contain"
            />
          </Link>
        </Styled.LogoViewport>

        <Styled.Viewport>
          {HeaderMenuDatas.map((data, i) => {
            return (
              <Styled.MenuItem
                key={data.path}
                onMouseEnter={(e) => {
                  if (!data.sub) return;
                  setSelectedMenuIndex(i);
                  setShowSelectSubMenu(true);
                }}
                onMouseLeave={(e) => {
                  setShowSelectSubMenu(false);
                }}
              >
                <Link to={data.sub ? `${data.path}${data.sub[0].path}` : data.path}>
                  <Typo.Pretendard_Medium fontSize={16} color={color}>
                    {data.title}
                  </Typo.Pretendard_Medium>
                </Link>
              </Styled.MenuItem>
            );
          })}
        </Styled.Viewport>
      </Styled.Header>

      {showSelectSubMenu && (
        <Styled.SelectSubMenu
          onMouseOver={(e) => {
            setShowSelectSubMenu(true);
          }}
          onMouseOut={(e) => {
            setShowSelectSubMenu(false);
          }}
        >
          {HeaderMenuDatas[selectedMenuIndex].sub!.map((e, i) => (
            <Styled.SelectSubMenuItem>
              <Link to={`${HeaderMenuDatas[selectedMenuIndex].path}${e.path}`}>
                <Typo.Pretendard_Medium fontSize={16} color={Colors.white}>
                  {e.title}
                </Typo.Pretendard_Medium>
              </Link>
            </Styled.SelectSubMenuItem>
          ))}
        </Styled.SelectSubMenu>
      )}

      {props && props.menuIndex !== undefined && props.subMenuIndex !== undefined && scrollPosition < 5 && (
        <Styled.SubMenu>
          <Styled.SubMenuContainer>
            {HeaderMenuDatas[props.menuIndex].sub!.map((e, i) => (
              <Styled.SubMenuItem key={e.path} backgroundColor={i === props.subMenuIndex ? Colors.gray300 : 'transparent'}>
                <Link to={`${HeaderMenuDatas[props.menuIndex!].path}${e.path}`}>
                  <Typo.Pretendard_Medium fontSize={16} color={i === props.subMenuIndex ? Colors.white : Colors.gray300}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                </Link>
              </Styled.SubMenuItem>
            ))}
          </Styled.SubMenuContainer>
        </Styled.SubMenu>
      )}
    </>
  );
}

export default Header;
