import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import BackgroundImage from 'img/photos/aracare/1/background.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 83px;
`;

export const TypeIconContainer = styled.div`
  width: 373px;
  height: 250px;

  position: relative;
  background: #fff9ea;
`;

export const TypeIcon = styled.img<{ top: number; left: number }>`
  position: absolute;

  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;

  padding: 54px 0 120px;

  background-image: url(${BackgroundImage});
  background-size: cover;
`;
