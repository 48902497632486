import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './aracare1.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import TypeImage0 from 'img/photos/aracare/1/type_0.png';
import TypeImage1 from 'img/photos/aracare/1/type_1.png';
import TypeImage2 from 'img/photos/aracare/1/type_2.png';
import TypeImage3 from 'img/photos/aracare/1/type_3.png';

const TypeDatas = [
  {
    title: '가다실 9가, 4가',
    content:
      '인두유종 바이러스에 의한 자궁경부암을 막아주는 백신입니다.\n자궁경부암 외에도 외음부암, 질암, 항문암 등을 예방할 수 있습니다.\n만 9세 이상 남녀모두가 접종할 수 있으며, 6개월에 걸쳐 3회 접종합니다.',
    image: TypeImage0,
    top: 50,
    left: 106,
  },
  {
    title: 'A형 간염 예방 백신',
    content:
      '물을 통해 감염되는 A형 간염을 막아주는 백신입니다.\n주로 젊은 사람이 감염되며, 임신 중 감염되면 유산의 위험이 커집니다.\n6개월에 걸쳐 2회 접종으로 평생 면역을 지킬 수 있습니다.',
    image: TypeImage1,
    top: 66,
    left: 97,
  },
  {
    title: 'B형 간염 예방 백신',
    content:
      '혈액 또는 성관계를 통해 감염되는 B형 간염을 막아주는 백신입니다.\n임신 중 B형 간염에 걸리면 유산 위험이 커지고, 태아에게 전염될 가능성도 높습니다.\n6개월에 걸쳐 3회 접종합니다.',
    image: TypeImage2,
    top: 48,
    left: 101,
  },
  {
    title: '풍진 예방 백신',
    content:
      '호흡기를 통해 감염되는 급성 감염성 질환인 풍진을 막아주는 백신입니다.\n임산부 감염 시 태아에게 전염되거나 기형을 초래할 수 있어 주의가 필요합니다.\n백신 접종 후에는 4주간 피임이 필요합니다.',
    image: TypeImage3,
    top: 50,
    left: 112,
  },
];

import DownArrow from 'img/photos/disease/DownArrow.png';

function AraCare1(): ReactElement {
  // const menuIndex = 4;
  // const subIndex = 1;
  const menuIndex = 3;
  const subIndex = 0;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />

      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            예방접종의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          {TypeDatas.map((e, i) => (
            <Common.FlexColumn key={e.title} width={1160} alignItems="stretch">
              <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
                0{i + 1}
              </Typo.BodoniMT_BoldItalic>
              <Common.SizedBox width={1160} height={2}>
                <Common.Fill color={Colors.gray200} />
              </Common.SizedBox>

              <Common.SizedBoxH height={32} />

              <Common.FlexRow>
                <Common.SizedBoxW width={35} />
                <Styled.TypeIconContainer>
                  <Styled.TypeIcon src={e.image} top={e.top} left={e.left} />
                </Styled.TypeIconContainer>

                <Common.SizedBoxW width={33} />
                <Common.FlexColumn>
                  <Common.SizedBoxH height={40} />
                  <Typo.Pretendard_Medium textAlign="left" color={Colors.gray200} fontSize={30}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={20} />
                  <Typo.Pretendard_Regular textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray200}>
                    {e.content}{' '}
                  </Typo.Pretendard_Regular>
                </Common.FlexColumn>
              </Common.FlexRow>

              {i < TypeDatas.length - 1 && <Common.SizedBoxH height={50} />}
            </Common.FlexColumn>
          ))}
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            치료만큼이나 예방이 중요합니다.
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={100} height={100} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={20} lineHeight={30} color={Colors.gray300}>
            예방접종은 연령에 따라 다양하게 권장됩니다.
            <br />
            아이부터 성인까지, 아라산부인과에서 제안하는
            <br />
            예방접종으로 질병 없이 건강한 삶을 누리세요!
          </Typo.Pretendard_Regular>
        </Styled.Content1>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default AraCare1;
