import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 120px;
`;

export const MarkText = styled(Typo.Pretendard_Regular)`
  mark {
    color: ${Colors.gold300};
    background-color: transparent;
    font-weight: 600;
  }
`;

export const Percent = styled.div`
  width: 177px;
  height: 177px;

  position: relative;
`;

export const PercentFrame = styled.img`
  width: 177px;
  height: 177px;

  position: absolute;
  top: 0;
  left: 0;
`;

export const PercentViewport = styled.div`
  position: absolute;

  width: 177px;
  height: 177px;

  padding: 11px 0 0 6px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PercentText = styled(Typo.Pretendard_ExtraBold)`
  vertical-align: bottom;

  mark {
    background-color: transparent;
    font-weight: 400;
    font-size: 23px;
  }
`;

export const CheckUpTitle = styled.div<{ color: string }>`
  width: 177px;
  height: 50px;
  border-radius: 25px;
  background-color: ${(p) => p.color};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 36px 0 1px;

  background-color: ${Colors.gold100};
`;

export const RecommandBox = styled.div`
  width: 120px;
  height: 40px;
  border: 1px solid var(--ce-9715, #ce9715);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 99px 0 120px;

  background-color: ${Colors.white};
`;

export const CautionBox = styled.div`
  width: 1004px;

  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  display: flex;
  flex-direction: column;

  padding: 39px 0 29px 52px;
`;
