import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 100px;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 92px 0 142px;

  background-color: ${Colors.gold100};
`;

export const Content1Title = styled.div`
  border: 1px solid var(--665-e-4-b, #665e4b);
  width: 180px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SymptomDescriptionText = styled(Typo.Pretendard_Regular)`
  color: #888888;
  font-size: 20px;
  line-height: 30px;
  text-align: left;

  mark {
    color: #665e4b;
    background-color: transparent;
  }
`;

export const SymptomBalloon = styled.div`
  width: 80px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background: var(--ebe-2-cc, #ebe2cc);
`;

export const SymptomContainer = styled.div`
  width: 865px;
  min-height: 138px;

  border-radius: 10px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  background-image: url(${SymptomContainerImage});
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px 25px;
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 100px 0 129px;
`;

export const CheckUpContainer = styled.div<{ backgroundImage: '*png' }>`
  width: 373px;
  height: 373px;

  padding: 30px;

  background-image: url(${(p) => p.backgroundImage});

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;
