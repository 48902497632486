import * as React from 'react';

type WindowState = {
  windowSize: { width: number; height: number };
  width: number;
  scrollPosition: number;
  isMobile: boolean;
};

export const WindowContext = React.createContext<WindowState>({
  windowSize: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  width: Math.min(1920, window.innerWidth),
  scrollPosition: 0,
  isMobile: false,
});

export const WindowProvider: React.FC<any> = ({ children }) => {
  //#region about windows
  const handleWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { width: innerWidth, height: innerHeight };
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const [windowSize, setWindowSize] = React.useState(handleWindowSize());
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const width = React.useMemo(() => Math.min(windowSize.width, 1920), [windowSize]);
  const isMobile = React.useMemo<boolean>(() => width < 1000, [width]);

  React.useEffect(() => {
    function handleWindowResize() {
      const windowSize = handleWindowSize();
      setWindowSize(windowSize);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (windowSize.width < 1000) {
      const redirectUrl = `https://m.araclinics.com${window.location.pathname}`;
      location.replace(redirectUrl);
    }
  }, [windowSize]);
  //#endregion

  const value = {
    windowSize,
    width,
    scrollPosition,
    isMobile,
  };

  return <WindowContext.Provider value={value}>{children}</WindowContext.Provider>;
};

export function adjustSize(size: number, defaultSize?: number): number {
  const context = React.useContext(WindowContext);
  if (context.isMobile) {
    if (context.windowSize.width / context.windowSize.height > 414 / 896) return (size * context.windowSize.height) / 896;
    return (size * context.windowSize.width) / 414;
  } else {
    if (defaultSize) return defaultSize;
    else return size;
  }
}
