import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './about2.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import DecoTextImage from 'img/photos/about/2/deco_text.png';
import DecoTextImage2 from 'img/photos/about/2/deco_text_2.png';
import Photo0 from 'img/photos/about/2/photo.png';
import Photo1 from 'img/photos/about/2/photo_1.png';
import Photo2 from 'img/photos/about/2/photo_2.jpg';
// import TimeImage from 'img/photos/about/2/time.jpg';
import Time0 from 'img/photos/about/2/time_0.jpg';
import Time1 from 'img/photos/about/2/time_1.jpg';
import Time2 from 'img/photos/about/2/time_2.jpg';

function About2(): ReactElement {
  const menuIndex = 0;
  const subIndex = 2;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />

      <Styled.Container>
        <Styled.TitleContainer>
          <Common.SizedBoxH height={120} />
          <Typo.Pretendard_ExtraBold fontSize={50} color={Colors.black}>
            의료진소개
          </Typo.Pretendard_ExtraBold>
          <Common.SizedBoxH height={14} />
          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            아라산부인과의원 의료진을 소개 합니다.
          </Typo.Pretendard_Regular>
        </Styled.TitleContainer>

        <Styled.Content0>
          <Common.FlexRow width={1160} alignItems="flex-end" justifyContent="space-between">
            <Styled.PhotoContainer>
              <Styled.PhotoImage src={Photo0} />
              <Styled.TextDeco src={DecoTextImage} />
            </Styled.PhotoContainer>

            <Common.FlexColumn width={515}>
              <Common.SizedImage src={DecoTextImage2} width={174} height={86} />
              <Common.SizedBoxH height={5} />
              <Common.FlexRow alignItems="flex-end">
                <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
                  남 선 미
                </Typo.Pretendard_Medium>
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Light fontSize={32} color={Colors.black}>
                  대표원장
                </Typo.Pretendard_Light>
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Medium fontSize={20} color={Colors.gold300}>
                  여성 산부인과 전문의
                </Typo.Pretendard_Medium>
              </Common.FlexRow>
              <Common.SizedBoxH height={25} />
              <Common.SizedBox width={515} height={2}>
                <Common.Fill color={Colors.gray100} />
              </Common.SizedBox>
              <Common.SizedBoxH height={25} />
              <Typo.Pretendard_Regular textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray300}>
                · 연세대학교 신촌세브란스 전공의
                <br />
                · 연세대학교 신촌세브란스 전문의
                <br />
                · 연세대학교 신촌세브란스 모체태아의학 전임의
                <br />
                · 전 김포모아제일산부인과 원장
                <br />
                · 전 더네이처산부인과 원장
                <br />
                · 전 오산산부인과 원장
                <br />
                · Severance Advanced clerkship 수료
                <br />
                · The University of Sydney Faculty of Medicine and Health Cadaveric workshop 수료
                <br />
                · 임산부 약물 상담 전문가 과정
                <br />
                · 대한폐경학회 폐경 전문 과정
                <br />
                · 대한산부인과학회 정회원
                <br />
                · 대한산부인과초음파학회 정회원
                <br />
                · International Society of Ultrasound in Obstetrics & Gynecology(ISUOG) 정회원
                <br />
                · 대한모체태아의학회 정회원
                <br />
                · 대한폐경학회 정회원
                <br />· 대한산부인과내분비학회 정회원
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>
          </Common.FlexRow>

          <Common.SizedBoxH height={50} />

          <Common.FlexRow width={1160}>
            <Common.Span />
            <Common.SizedImage width={800} src={Time0} />
          </Common.FlexRow>

          <Common.FlexRow width={1160} alignItems="flex-end" justifyContent="space-between">
            <Styled.PhotoContainer>
              <Styled.PhotoImage src={Photo1} />
              <Styled.TextDeco src={DecoTextImage} />
            </Styled.PhotoContainer>

            <Common.FlexColumn width={515}>
              <Common.SizedImage src={DecoTextImage2} width={174} height={86} />
              <Common.SizedBoxH height={5} />
              <Common.FlexRow alignItems="flex-end">
                <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
                  조 규 희
                </Typo.Pretendard_Medium>
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Light fontSize={32} color={Colors.black}>
                  원장
                </Typo.Pretendard_Light>
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Medium fontSize={20} color={Colors.gold300}>
                  여성 산부인과 전문의
                </Typo.Pretendard_Medium>
              </Common.FlexRow>
              <Common.SizedBoxH height={25} />
              <Common.SizedBox width={515} height={2}>
                <Common.Fill color={Colors.gray100} />
              </Common.SizedBox>
              <Common.SizedBoxH height={25} />
              <Typo.Pretendard_Regular textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray300}>
                · 연세대학교 신촌세브란스 전공의
                <br />· 연세대학교 신촌세브란스 전문의
                <br />· 전 채움산부인과 원장
                <br />· 전 예산부인과 원장
                <br />· 전 애플산부인과 원장
                <br />· 대한산부인과학회 정회원
                <br />· 대한산부인과초음파학회 정회원
                <br />· 대한모체태아의학회 정회원
                <br />· 대한폐경학회 정회원
                <br />· 대한산부인과내분비학회 정회원
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>
          </Common.FlexRow>

          <Common.SizedBoxH height={50} />

          <Common.FlexRow width={1160}>
            <Common.Span />
            <Common.SizedImage width={800} src={Time1} />
          </Common.FlexRow>

          <Common.FlexRow width={1160} alignItems="flex-end" justifyContent="space-between">
            <Styled.PhotoContainer>
              <Styled.PhotoImage src={Photo2} />
              <Styled.TextDeco src={DecoTextImage} />
            </Styled.PhotoContainer>

            <Common.FlexColumn width={515}>
              <Common.SizedImage src={DecoTextImage2} width={174} height={86} />
              <Common.SizedBoxH height={5} />
              <Common.FlexRow alignItems="flex-end">
                <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
                  이 태 경
                </Typo.Pretendard_Medium>
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Light fontSize={32} color={Colors.black}>
                  원장
                </Typo.Pretendard_Light>
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Medium fontSize={20} color={Colors.gold300}>
                  여성 산부인과 전문의
                </Typo.Pretendard_Medium>
              </Common.FlexRow>
              <Common.SizedBoxH height={25} />
              <Common.SizedBox width={515} height={2}>
                <Common.Fill color={Colors.gray100} />
              </Common.SizedBox>
              <Common.SizedBoxH height={25} />
              <Typo.Pretendard_Regular textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray300}>
                · 연세대학교 신촌세브란스 전공의
                <br />· 연세대학교 신촌세브란스 전문의
                <br />· 연세대학교 신촌세브란스 부인종양학 전임의
                <br />· 전)연세대학교 신촌세브란스 임상연구조교수
                <br />· 전)인하대학교 산부인과 임상조교수
                <br />· LA, USC keck medical school Clerkship 수료
                <br />· SLN Korea- Stryker FILM Master's Course 수료
                <br />· 대한산부인과학회 정회원
                <br />· 대한부인종양학회 정회원
                <br />· 대한단일공수술학회 정회원
                <br />· 대한산부인과내시경학회 정회원
                <br />· 대한산부인과로봇수술학회 정회원
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>
          </Common.FlexRow>

          <Common.SizedBoxH height={50} />

          <Common.FlexRow width={1160}>
            <Common.Span />
            <Common.SizedImage width={800} src={Time2} />
          </Common.FlexRow>
        </Styled.Content0>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default About2;
