import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.white};
  background: linear-gradient(180deg, #ebe2cc 0%, #f1e4c4 29.69%, rgba(235, 226, 204, 0.47) 100%);

  background-size: 100% 500px;
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 120px;
`;

export const Input = styled.input`
  width: 994px;
  height: 48px;

  border: 1px solid #eee;
  background: #fff9ea;

  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: Pretendard;
  font-weight: 400;
  line-height: normal;
  font-size: 20px;
  color: ${Colors.gray300};
`;

export const TextArea = styled.textarea`
  width: 994px;
  height: 350px;

  border: 1px solid #eee;
  background: #fff9ea;

  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: Pretendard;
  font-weight: 400;
  line-height: normal;
  font-size: 20px;
  color: ${Colors.gray300};
`;

export const SendButton = styled.button`
  width: 231px;
  height: 74px;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff9ea;
  display: flex;
  align-items: center;
  justify-content: center;
`;
