import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease9.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/9/banner.png';

import CheckImage from 'img/photos/disease/4/check.png';

const CauseDatas = [
  '반복된 임신과 출산으로 인한 경우',
  '노화로 방광을 지지하는 근육이 늘어진 경우',
  '당뇨, 중풍 등 만성 질환 혹은 골반부위 수술로 인한 경우',
  '방광염, 요도염으로 인한 경우',
];

import TypeImage0 from 'img/photos/disease/9/type_0.png';
import TypeImage1 from 'img/photos/disease/9/type_1.png';
import TypeImage2 from 'img/photos/disease/9/type_2.png';
import TypeImage3 from 'img/photos/disease/9/type_3.png';

const TypeDatas = [
  {
    title: '복압성 요실금',
    content: '가장 흔한 요실금의 종류로, 기침과 재채기 등\n배에 힘이 가해지는 사소한 행동으로도 소변이 새어 나오는 증상이 생깁니다.',
    image: TypeImage0,
    top: 20,
    left: 145,
  },
  {
    title: '절박성 요실금',
    content: '방광 근육이 불안정하여 발생하는 요실금으로,\n갑자기 강하게 소변이 마려운 증상이 생깁니다.',
    image: TypeImage1,
    top: 13,
    left: 95,
  },
  {
    title: '복합성 요실금',
    content: '고령의 여성에게서 발생률이 높은 요실금으로,\n복압성 요실금과 절박성 요실금이 혼합된 형태입니다.',
    image: TypeImage2,
    top: 5,
    left: 80,
  },
  {
    title: '일류성 요실금',
    content:
      '방광 신경 기능이 약해지거나 만성적인 약물 복용 또는\n평소에 소변을 오래 참는 습관으로 인해 방광 수축력이 떨어져 발생하는 요실금입니다.',
    image: TypeImage3,
    top: 9,
    left: 74,
  },
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '비수술적 치료',
    description: '약물 치료, 전기 자극 치료 등',
    image: TreatmentImage0,
  },
  {
    title: '수술적 치료',
    description: '요실금을 막아주는 구조물 삽입',
    image: TreatmentImage1,
  },
];

function Disease9(): ReactElement {
  const menuIndex = 2;
  const subIndex = 9;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Incontinence
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            요실금
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            아라산부인과가 맞춤 치료를 통해
            <br />
            생활 속 편안함을 되찾아드리겠습니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    본인의 의지와 상관없이 자신도 모르게 소변이 새는 현상을 말합니다. 우리나라 여성의 40%가 요실금 증상을 경험한다고 알려져
                    있으며, 기침과 재채기를 하면 소변이 새어 나와 일상에서의 큰 불편을 호소합니다.{' '}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            요실금 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={80} />

          <Styled.CauseConatiner>
            {CauseDatas.map((e, i) => (
              <>
                <Common.FlexRow alignItems="center">
                  <Styled.CheckContainer>
                    <Styled.CheckImageContainer src={CheckImage} />
                  </Styled.CheckContainer>
                  <Common.SizedBoxW width={33} />
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200} textAlign="left">
                    {e}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>

                <Common.SizedBoxH height={12} />

                <Styled.CauseDash />

                {i < CauseDatas.length - 1 && <Common.SizedBoxH height={23} />}
              </>
            ))}
          </Styled.CauseConatiner>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            요실금의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          {TypeDatas.map((e, i) => (
            <Common.FlexColumn key={e.title} width={1160} alignItems="stretch">
              <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
                0{i + 1}
              </Typo.BodoniMT_BoldItalic>
              <Common.SizedBox width={1160} height={2}>
                <Common.Fill color={Colors.gray200} />
              </Common.SizedBox>

              <Common.SizedBoxH height={32} />

              <Common.FlexRow>
                <Common.SizedBoxW width={35} />
                <Styled.TypeIconContainer>
                  <Styled.TypeIcon src={e.image} top={e.top} left={e.left} />
                </Styled.TypeIconContainer>

                <Common.SizedBoxW width={33} />
                <Common.FlexColumn>
                  <Common.SizedBoxH height={40} />
                  <Typo.Pretendard_Medium textAlign="left" color={Colors.gray200} fontSize={30}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={20} />
                  <Typo.Pretendard_Regular textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray200}>
                    {e.content}{' '}
                  </Typo.Pretendard_Regular>
                </Common.FlexColumn>
              </Common.FlexRow>

              {i < TypeDatas.length - 1 && <Common.SizedBoxH height={50} />}
            </Common.FlexColumn>
          ))}

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Self - Diagnosis
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            요실금 자가진단
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={44} />
                <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={20} color={Colors.gray200}>
                  요실금은 생명에 위험을 초래하는 질환은 아니지만 일상생활에서 큰 불편을 초래합니다.
                  <br />
                  아래와 같은 증상으로 인해 불편을 겪고 있다면 산부인과를 방문하는 것을 권합니다.{' '}
                </Typo.Pretendard_Regular>
                <Common.SizedBoxH height={55} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        기침이나 재채기를 하면 나도 모르게 소변이 새어 나와 속옷을 적신 적이 있다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        밤에 잠을 자다가 소변이 마려워 자주 깬다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        소변을 봐도 시원하지 않고 소변 줄기에 힘이 없다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        4.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        갑자기 소변이 마렵기 시작하면 참을 수가 없다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            요실금 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={53} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content3>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease9;
