import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import CauseContainerImage from 'img/photos/disease/4/cause_container.png';
import BackgroundImage from 'img/photos/disease/4/background.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 89px;
`;

export const StepContainer = styled.div`
  width: 1160px;
  height: 376px;

  position: relative;
`;

export const StepViewport = styled.div`
  width: 1160px;
  height: 376px;

  border-radius: 188px 188px 0px 0px;
  background: #fff9ea;

  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepText = styled(Typo.StyledText)`
  color: #888;
  text-align: center;
  font-size: 20px;
  font-family: Pretendard;
  line-height: 30px;

  mark {
    color: ${Colors.gray300};
    background-color: transparent;
  }
`;

export const StepImageContainer = styled.img`
  width: 871px;
  height: 871px;

  position: absolute;
  bottom: 144px;
  left: 145px;

  z-index: -10;
`;

import StepBarImage from 'img/photos/disease/8/step_bar.png';

export const StepBar = styled.div`
  width: 963.798px;
  height: 52.756px;
  padding: 0 0 0 15px;
  background-image: url(${StepBarImage});

  display: flex;
  align-items: center;
  justify-content: center;

  background-size: 100% 100%;
`;

export const SymptomBalloon = styled.div`
  width: 80px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background: var(--ebe-2-cc, #ebe2cc);
`;

export const SymptomContainer = styled.div`
  width: 865px;
  min-height: 138px;

  border-radius: 10px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  background-image: url(${SymptomContainerImage});
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px 25px;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 58px 0 116px;

  background-image: url(${BackgroundImage});
  background-size: cover;
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 95px 0 80px;

  background-color: ${Colors.gold100};
`;

export const TreatmentContainer = styled.div`
  width: 964px;
  height: 200px;

  position: relative;

  border-radius: 20px;
  background-color: ${Colors.white};
`;

export const TreatmentContainerViewport = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 964px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;

  display: flex;
`;

export const TreatmentIndexContainer = styled.div`
  width: 93px;
  height: 93px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -53px;
  top: -30px;

  z-index: 1;
`;
