import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease2.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/1/banner.png';

import CauseIcon0 from 'img/photos/disease/2/causeIcons/0.png';
import CauseIcon1 from 'img/photos/disease/2/causeIcons/1.png';
import CauseIcon2 from 'img/photos/disease/2/causeIcons/2.png';

const CauseDatas = [
  {
    title: '자궁 • 난소질환',
    contents: ['자궁내막증', '자궁내막, 경부 용종', '근종, 선근종', '자궁내막암, 경부암'],
    icon: CauseIcon0,
  },
  {
    title: '호르몬 이상',
    contents: ['조기폐경', '갑상선 질환', '고프로락틴혈증'],
    icon: CauseIcon1,
  },
  {
    title: '일시적 • 생리적 이상',
    contents: ['무배란', '스트레스', '약물'],
    icon: CauseIcon2,
  },
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/2/check_2.png';

const HowToCheckDatas = [
  {
    title: '문진',
    description: '병력 청취 및 생활 습관 파악',
    image: CheckImage0,
  },
  {
    title: '원인 검사',
    description: '초음파 검사, 호르몬 검사, 혈액 검사',
    image: CheckImage1,
  },
  {
    title: '임신검사',
    image: CheckImage2,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/0/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '호르몬 요법',
    description: '월경양을 조절하는 호르몬제, 호르몬 장치, 호르몬 주사 처방',
    image: TreatmentImage0,
  },
  {
    title: '원인 질환 치료',
    description: '비정상적인 월경양의 원인 질환을 찾아 치료 진행',
    image: TreatmentImage1,
  },
];

function Disease2(): ReactElement {
  const menuIndex = 2;
  const subIndex = 2;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Menorrhagia ㅣ Hypomenorrhea
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경과다/월경과소
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            아라산부인과의 빠른 진단으로 안정적인 주기를 되찾으세요.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={89} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    평소보다 생리량이 너무 많은 경우 또는 생리량이 너무 적은 경우에 해당합니다.
                    <br />
                    일시적인 경우를 제외하고 생리량이 불규칙한 경우엔 반드시 내원하여 원인을 파악해야 합니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경과다/월경과소의 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={94} />

          <Common.FlexRow width={865} alignItems="center" justifyContent="space-between">
            {CauseDatas.map((e, i) => (
              <Styled.CauseCard key={e.title}>
                <Styled.CauseCardIconContainer>
                  <Common.SizedImage src={e.icon} />
                </Styled.CauseCardIconContainer>
                <Styled.CauseCardViewport>
                  <Common.SizedBoxH height={63} />
                  <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300} lineHeight={32}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={20} />
                  <Common.FlexRow>
                    <Common.SizedBoxW width={40} />
                    <Common.FlexColumn>
                      {e.contents.map((e, i) => (
                        <Typo.Pretendard_Regular textAlign="left" fontSize={20} height={32} color={Colors.gray300}>
                          &nbsp;&nbsp;• {e}
                        </Typo.Pretendard_Regular>
                      ))}
                    </Common.FlexColumn>
                  </Common.FlexRow>
                </Styled.CauseCardViewport>
              </Styled.CauseCard>
            ))}
          </Common.FlexRow>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경과다/월경과소의 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={63} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={58} />

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexRow alignItems="center">
                <Styled.SymptomBalloon>
                  <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                    증상
                  </Typo.Pretendard_Regular>
                </Styled.SymptomBalloon>

                <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                <Common.SizedBoxW width={20} />

                <Styled.SymptomContainer>
                  <Common.FlexRow height={40} alignItems="center">
                    <Typo.Abril fontSize={20} color="#665E4B">
                      1.
                    </Typo.Abril>
                    <Common.SizedBoxW width={14} />
                    <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                      생리량이 150ml 이상일때 (오버나이트 가득 2-3시간에 하나 쓸경우){' '}
                    </Typo.Pretendard_Regular>
                  </Common.FlexRow>
                  <Common.FlexRow height={40} alignItems="center">
                    <Typo.Abril fontSize={20} color="#665E4B">
                      2.
                    </Typo.Abril>
                    <Common.SizedBoxW width={14} />
                    <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                      월경량이 너무 적다. (팬티라이너에 묻는 정도)
                    </Typo.Pretendard_Regular>
                  </Common.FlexRow>
                  <Common.FlexRow height={40} alignItems="center">
                    <Typo.Abril fontSize={20} color="#665E4B">
                      3.
                    </Typo.Abril>
                    <Common.SizedBoxW width={14} />
                    <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                      일주일 넘계 월경이 지속된다.
                    </Typo.Pretendard_Regular>
                  </Common.FlexRow>
                </Styled.SymptomContainer>
              </Common.FlexRow>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={200} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경과다/월경과소 검사 방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={63} />

          <Common.FlexRow justifyContent="center" alignItems="center">
            {HowToCheckDatas.map((e, i) => (
              <>
                <Common.SizedBoxW width={10} key={`${e.title}0`} />
                <Styled.CheckUpContainer backgroundImage={e.image} key={`${e.title}1`}>
                  <Typo.Pretendard_Medium fontSize={30} color={Colors.white}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  {e.description && (
                    <>
                      <Common.SizedBoxH height={11} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.white}>
                        {e.description}
                      </Typo.Pretendard_Regular>
                    </>
                  )}
                </Styled.CheckUpContainer>
                <Common.SizedBoxW width={10} key={`${e.title}2`} />
              </>
            ))}
          </Common.FlexRow>
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경과다/월경과소 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={13} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content3>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease2;
