import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  white-space: pre-line;

  padding: 100px 0 100px;
`;

export const RightsContainer = styled.div`
  width: 1160px;

  padding: 40px;

  display: flex;
  flex-direction: column;

  background-color: ${Colors.gray100};
  border-radius: 20px;
`;

export const RightsInnerContainer = styled.div`
  border: 1px solid ${Colors.gray200};
  background-color: ${Colors.white};
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const RightsText = styled(Typo.Pretendard_Light)`
  text-align: left;
  color: ${Colors.black};
  font-size: 16px;
  line-height: 21px;

  mark {
    font-weight: 700;
    background-color: transparent;
  }
`;
