import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

import BackgroundImage from 'img/photos/about/0/background.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.white};
  background: linear-gradient(180deg, #ebe2cc 0%, #f1e4c4 29.69%, rgba(235, 226, 204, 0.47) 100%);

  background-size: 100% 500px;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 821px;

  background-image: url(${BackgroundImage});

  background-position: center;
  background-size: cover;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 130px 0 100px;

  background-color: ${Colors.gold100};
`;

export const StoryContainer = styled.div`
  width: 964px;
  height: 200px;

  position: relative;

  border-radius: 20px;
  background-color: ${Colors.white};
`;

export const StoryIconContainer = styled.div`
  width: 295px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${Colors.gold200};
`;

export const StoryContainerViewport = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 964px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;

  display: flex;
`;

export const StoryIndexContainer = styled.div`
  width: 93px;
  height: 93px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -53px;
  top: -30px;

  z-index: 1;
`;

export const StoryTitleText = styled(Typo.Pretendard_Medium)`
  font-size: 30px;
  text-align: left;
  color: ${Colors.gray300};

  mark {
    color: ${Colors.gold300};
    background-color: transparent;
  }
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 100px 0 173px;

  background-color: ${Colors.white};
`;

export const WhyCard = styled.div`
  width: 373px;
  height: 300px;
  background: #ebe2cc;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhyCardViewport = styled.div`
  width: 289px;
  height: 232px;
  border-radius: 0px 0px 0px 100px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 21px;
`;
