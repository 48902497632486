import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './about3.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';

import { Colors } from 'common';
import { Container as MapDiv, NaverMap, Marker, useNavermaps, useMap } from 'react-naver-maps';
import { mapCenter } from 'common/datas';
import { Icons } from 'common/images';

function About3(): ReactElement {
  const menuIndex = 0;
  const subIndex = 3;

  const navermaps = useNavermaps();
  const map = useMap();

  const mapStyle = {
    width: '1160px',
    height: '600px',
    border: 'none',
  };

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />

      <Styled.Container>
        <Styled.TitleContainer>
          <Common.SizedBoxH height={120} />
          <Typo.Pretendard_ExtraBold fontSize={50} color={Colors.black}>
            진료시간/오시는길
          </Typo.Pretendard_ExtraBold>
          <Common.SizedBoxH height={14} />
          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            아라산부인과의원 진료시간과 오시는 길을 소개 합니다.
          </Typo.Pretendard_Regular>
        </Styled.TitleContainer>

        <Styled.Content0>
          <MapDiv style={mapStyle}>
            <NaverMap defaultCenter={mapCenter} defaultZoom={15}>
              <Marker defaultPosition={mapCenter} />
            </NaverMap>
          </MapDiv>

          <Common.SizedBoxH height={48} />
          <Common.FlexRow alignItems="flex-start" justifyContent="space-between" width={1160}>
            <Common.FlexColumn width={350} alignItems="stretch">
              <Common.FlexRow alignItems="center">
                <Common.SizedBoxW width={8} />
                <Common.SizedImage src={Icons.Ping} width={30} height={30} />
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300}>
                  오시는 길
                </Typo.Pretendard_Medium>
              </Common.FlexRow>
              <Common.SizedBoxH height={10} />
              <Common.SizedBox width={350} height={1}>
                <Common.Fill color="#815233" />
              </Common.SizedBox>
              <Common.SizedBoxH height={20} />
              <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={18} lineHeight={40}>
                인천 서구 이음대로 392,
                <br />
                5층 검단아라산부인과의원
                <br />
                (스타벅스 검단신도시점 건물)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.FlexColumn width={350} alignItems="stretch">
              <Common.FlexRow alignItems="center">
                <Common.SizedBoxW width={8} />
                <Common.SizedImage src={Icons.Clock} width={30} height={30} />
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300}>
                  진료시간
                </Typo.Pretendard_Medium>
              </Common.FlexRow>
              <Common.SizedBoxH height={10} />
              <Common.SizedBox width={350} height={1}>
                <Common.Fill color="#815233" />
              </Common.SizedBox>
              <Common.SizedBoxH height={20} />
              <Common.FlexRow width={350} justifyContent="space-between">
                <Typo.Pretendard_Regular width={156} textAlign="left" color={Colors.gray200} fontSize={18} lineHeight={40}>
                  평일
                  <br />
                  토요일
                  <br />
                  <br />
                  일요일&공휴일 휴진
                </Typo.Pretendard_Regular>
                <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={18} lineHeight={40}>
                  AM 09:00 - PM 08:00 <br />
                  AM 09:00 - PM 04:00 <br />
                  (점심시간 없이 진료)
                </Typo.Pretendard_Regular>
              </Common.FlexRow>
            </Common.FlexColumn>

            <Common.FlexColumn width={350} alignItems="stretch">
              <Common.FlexRow alignItems="center">
                <Common.SizedBoxW width={8} />
                <Common.SizedImage src={Icons.Phone} width={30} height={30} />
                <Common.SizedBoxW width={10} />
                <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300}>
                  상담 및 예약
                </Typo.Pretendard_Medium>
              </Common.FlexRow>
              <Common.SizedBoxH height={10} />
              <Common.SizedBox width={350} height={1}>
                <Common.Fill color="#815233" />
              </Common.SizedBox>
              <Common.SizedBoxH height={27} />
              <Typo.BodoniMT_BoldItalic fontSize={48} color={Colors.gray300} textAlign="left">
                032 - 569 - 7575
              </Typo.BodoniMT_BoldItalic>
            </Common.FlexColumn>
          </Common.FlexRow>
        </Styled.Content0>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default About3;
