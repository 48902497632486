import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

export const Header = styled.div<{ backgroundColor?: string }>`
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${(p) => p.backgroundColor && `background-color: ${p.backgroundColor}`};

  z-index: 100;
`;

export const Viewport = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LogoViewport = styled.div`
  position: absolute;
  left: 20px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuItem = styled.div`
  padding: 0 40px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 50px;
  top: 70px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  z-index: 50;
`;

export const SubMenuContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 20px 20px;

  overflow: hidden;
`;

export const SubMenuItem = styled.div<{ backgroundColor?: string }>`
  padding: 0 18px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  ${(p) => p.backgroundColor && `background-color: ${p.backgroundColor}`};
`;

export const SelectSubMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 50px;
  top: 70px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${Colors.gray300};

  z-index: 90;
`;

export const SelectSubMenuItem = styled.div`
  padding: 0 18px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
`;
