import styled from 'styled-components';

export const StyledText = styled.div<{
  textAlign?: string;
  verticalAlign?: string;
  fontSize?: number;
  fontStyle?: string;
  width?: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
  textDecoration?: string;
  fontFamily?: string;
  fontWeight?: string | number;
  lineHeight?: string | number;
}>`
  font-stretch: normal;
  letter-spacing: normal;
  white-space: pre-line;

  ${(props) => props.lineHeight && typeof props.lineHeight === 'number' && `line-height: ${props.lineHeight}px`};
  ${(props) => props.lineHeight && typeof props.lineHeight === 'string' && `line-height: ${props.lineHeight}`};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};
  ${(props) => props.fontFamily && `font-family: ${props.fontFamily}`};
  ${(props) => props.fontStyle && `font-style: ${props.fontStyle}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.verticalAlign && `vertical-align: ${props.verticalAlign}`};
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`};
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor}`};
  ${(props) => props.width && `width: ${props.width}px`};
  ${(props) => props.height && `height: ${props.height}px`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
`;

export const Inter_Ragular = styled(StyledText)`
  font-family: Inter;
  font-weight: 400;
`;

export const Pretendard_ExtraLight = styled(StyledText)`
  font-family: Pretendard;
  font-weight: 200;
`;

export const Pretendard_Light = styled(StyledText)`
  font-family: Pretendard;
  font-weight: 300;
`;

export const Pretendard_Regular = styled(StyledText)`
  font-family: Pretendard;
  font-weight: 400;
`;

export const Pretendard_Medium = styled(StyledText)`
  font-family: Pretendard;
  font-weight: 500;
`;

export const Pretendard_ExtraBold = styled(StyledText)`
  font-family: Pretendard;
  font-weight: 800;
`;

export const Baskerville_Regular = styled(StyledText)`
  font-family: Baskerville;
  font-weight: 400;
`;

export const Abril = styled(StyledText)`
  font-family: Abril;
`;

export const BodoniMT_BoldItalic = styled(StyledText)`
  font-family: BodoniMT;
  font-weight: 700;
  font-style: italic;
`;

export const BodoniModa_Italic = styled(StyledText)`
  font-family: BodoniModa;
  font-style: italic;
`;
