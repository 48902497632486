import Logo from 'img/common/Logo_new.png';
import LogoWhite from 'img/common/Logo_new_white.png';

export const CommonImages = {
  Logo,
  LogoWhite,
};

// icons
import ArrowLeft from 'img/icons/ArrowLeft.svg';
import ArrowRight from 'img/icons/ArrowRight.svg';
import Clock from 'img/icons/Clock.svg';
import Ping from 'img/icons/Location.svg';
import Phone from 'img/icons/Phone.svg';

export const Icons = {
  ArrowLeft,
  ArrowRight,
  Clock,
  Ping,
  Phone,
};
