import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  height: 755px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: ${Colors.gold200};
`;

export const FooterLogo = styled.img`
  width: 430px;
  height: 81px;
  object-fit: contain;
  mix-blend-mode: overlay;
`;
