import About0 from './about0';
import About1 from './about1';
import About2 from './about2';
import About3 from './about3';
import About4 from './about4';

export default {
  About0,
  About1,
  About2,
  About3,
  About4,
};
