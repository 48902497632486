import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import CauseContainerImage from 'img/photos/disease/4/cause_container.png';
import BackgroundImage from 'img/photos/disease/4/background.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 100px;
`;

export const CheckUpGrid = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 373px);
  grid-template-rows: repeat(2, 373px);
  grid-gap: 21px;

  align-items: center;
  justify-content: center;
`;

export const CheckUpContainer = styled.div<{ backgroundImage: '*png' }>`
  width: 373px;
  height: 373px;

  padding: 30px;

  background-image: url(${(p) => p.backgroundImage});

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 50px 0 104px;

  background-color: ${Colors.gold100};
`;

export const TreatmentContainer = styled.div`
  width: 964px;
  height: 200px;

  position: relative;

  border-radius: 20px;
  background-color: ${Colors.white};
`;

export const TreatmentContainerViewport = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 964px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;

  display: flex;
`;

export const TreatmentIndexContainer = styled.div`
  width: 93px;
  height: 93px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -53px;
  top: -30px;

  z-index: 1;
`;
