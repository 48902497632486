import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease7.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/6/banner.png';

import TypeImage0 from 'img/photos/disease/7/typeImages/0.jpg';
import TypeImage1 from 'img/photos/disease/7/typeImages/1.jpg';
import TypeImage2 from 'img/photos/disease/7/typeImages/2.jpg';
import TypeImage3 from 'img/photos/disease/7/typeImages/3.jpg';
import TypeImage4 from 'img/photos/disease/7/typeImages/4.jpg';

import SymptomArrow from 'img/photos/disease/Arrow.svg';
import DownArrow from 'img/photos/disease/DownArrow.png';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';
import TreatmentImage2 from 'img/photos/disease/4/treat_1.jpg';
import TreatmentImage3 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '먹는 약',
    description: '',
    image: TreatmentImage0,
  },
  {
    title: '바르는 약',
    description: '',
    image: TreatmentImage1,
  },
  {
    title: '주사',
    description: '항생제 주사, 질 점막 주사, 영양 주사 등을 처방합니다.',
    image: TreatmentImage2,
  },
  {
    title: '질스케일링',
    description: '세균과 곰팡이가 번식하는 환경을 개선하여 재발을 예방합니다.',
    image: TreatmentImage3,
  },
];

function Disease7(): ReactElement {
  const menuIndex = 2;
  const subIndex = 7;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Vaginitis
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            질염
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            여성이 산부인과에 내원하는 경우 가장 흔한 증상은 냉 또는 대하증입니다.
            <br />
            이런 증상을 가진 여성의 약 40% 이상은 질염으로 진단되며 발적, 부종과 자극성 분비물, 작열감, 가려움증과 냄새등의 증상을
            나타냅니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    여성의 생식기인 질에 여러가지 원인으로 염증이 생기는 경우를 말합니다. 산부인과 질환 중에 가장 흔한 질환으로,
                    <br />
                    재발이 잦고 만성으로 이어지는 경우가 있어 적절한 치료가 필요합니다. 감추지 말고 치료하여 건강한 일상을 되찾으세요.{' '}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            질염의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={32} />
            <Common.FlexRow>
              <Common.SizedBoxW width={30} />
              <Common.SizedImage src={TypeImage0} width={373} height={250} objectFit="cover" />
              <Common.SizedBoxW width={39} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={40} />
                <Typo.Pretendard_Medium textAlign="left" color={Colors.gray200} fontSize={30}>
                  세균성 질증
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.TypeText>
                  세균성 질증은 어떤 원인으로 인해 질 내의 서식균이 세력을 읽고
                  <br />
                  <mark>전체 1% 미만으로 존재하던 혐기성 세균이 1000배로 증식하여 생기는 질염</mark>입니다.
                  <br />
                  세균성 질증이 있는 경우 <mark>골반염이 생길 확률이 높아지며, 후유증으로 나팔관이 막혀 불임</mark>
                  <br />이 되기도 합니다.
                </Styled.TypeText>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={50} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              02
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={32} />
            <Common.FlexRow>
              <Common.SizedBoxW width={30} />
              <Common.SizedImage src={TypeImage1} width={373} height={250} objectFit="cover" />
              <Common.SizedBoxW width={39} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={40} />
                <Typo.Pretendard_Medium textAlign="left" color={Colors.gray200} fontSize={30}>
                  트리코모나스 질염
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.TypeText>
                  트리코모나스는 기생충의 일종입니다. <mark>트리코모나스 질염은 성관계를 통해 전파되는 성병</mark>으로,
                  <br />
                  전파력이 매우 강해 <mark>감염된 파트너와 한 번만 성관계를 해도 70% 이상이 감염</mark>됩니다.
                  <br />
                  트리코모나스가 <mark>방광으로 침입하면 방광염, 오줌소태를 유발</mark>하며 자궁내막을 타고 올라가
                  <br />
                  <mark>골반염을 일으키키도 합니다.</mark>
                </Styled.TypeText>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={50} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              03
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={32} />
            <Common.FlexRow>
              <Common.SizedBoxW width={30} />
              <Common.SizedImage src={TypeImage2} width={373} height={250} objectFit="cover" />
              <Common.SizedBoxW width={39} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={40} />
                <Typo.Pretendard_Medium textAlign="left" color={Colors.gray200} fontSize={30}>
                  염증성 질염
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.TypeText>
                  <mark>염증성 상피세포가 심하게 떨어져 나오면서 고름 같은 냉이 많이 생기는 경우</mark>를 말합니다.
                  <br />
                  성교통, 외음부가 화끈거림, 냉의 양 증가 등의 증상을 유발합니다.
                </Styled.TypeText>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={50} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              04
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={32} />
            <Common.FlexRow>
              <Common.SizedBoxW width={30} />
              <Common.SizedImage src={TypeImage3} width={373} height={250} objectFit="cover" />
              <Common.SizedBoxW width={39} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={40} />
                <Typo.Pretendard_Medium textAlign="left" color={Colors.gray200} fontSize={30}>
                  위축성 질염
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.TypeText>
                  <mark>에스트로겐 결핍으로 질 벽이 얇아져 나타나는 질염</mark>입니다.
                  <br />
                  에스트로겐은 여성의 난소에서 주로 생성되기 때문에{' '}
                  <mark>
                    난소 양쪽을 다 잘라내는 수술을 받았
                    <br />
                    거나 폐경이후의 여성
                  </mark>
                  에게 나타납니다.
                  <br />
                  서교통, 다량의 냉, 질 건조증 등의 증상을 유발합니다.
                </Styled.TypeText>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={50} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              05
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={32} />
            <Common.FlexRow>
              <Common.SizedBoxW width={30} />
              <Common.SizedImage src={TypeImage4} width={373} height={250} objectFit="cover" />
              <Common.SizedBoxW width={39} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={40} />
                <Typo.Pretendard_Medium textAlign="left" color={Colors.gray200} fontSize={30}>
                  칸디다성 질염
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.TypeText>
                  여성 전체의 75%가 살아가는 동안 단 한번 이상 겪는다고 하는 가장 흔한 형태의 질염입니다.
                  <br />
                  45%의 여성이 1년에 2회 이상의 재발을 겪는다고 알려져 있습니다.
                  <br />
                  흰색의 걸쭉한 냉과 심한 가려움증이 대표작인 증상입니다.
                </Styled.TypeText>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            질염 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={44} />
                <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={20} color={Colors.gray200}>
                  질염을 제때 치료하지 않고 방치하면 방광염, 요도염, 골반염 등으로 진행될 수 있습니다.
                  <br />
                  아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.
                </Typo.Pretendard_Regular>
                <Common.SizedBoxH height={55} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        질 분비물 과다
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        생선 냄새와 같은 악취
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        참기 힘든 가려움
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        4.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        배뇨통/성교통
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            질염 검사
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={100} height={100} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={20} lineHeight={30} color={Colors.gray300}>
            질염은 질 분비물을 채취하여 PCR검사를 진행합니다.
            <br />
            1~2일 내에 검사 결과를 알 수 있으며, 균의 종류에 따라 추가 검사가 필요한 경우도 있습니다.
            <br />
            질염 검사는 매우 간다하며 통증이나 질 내 손상이 없습니다.
            <br />
            따라서 검사를 두려워 하지 마시고 내원하셔서 검사 받으시길 권합니다.
          </Typo.Pretendard_Regular>
        </Styled.Content3>

        <Styled.Content4>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            질염 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={23} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content4>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease7;
