import Disease0 from './disease0';
import Disease1 from './disease1';
import Disease2 from './disease2';
import Disease3 from './disease3';
import Disease4 from './disease4';
import Disease5 from './disease5';
import Disease6 from './disease6';
import Disease7 from './disease7';
import Disease8 from './disease8';
import Disease9 from './disease9';
import Disease10 from './disease10';

export default {
  Disease0,
  Disease1,
  Disease2,
  Disease3,
  Disease4,
  Disease5,
  Disease6,
  Disease7,
  Disease8,
  Disease9,
  Disease10,
};
