import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease1.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/1/banner.png';

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/0/check_2.png';

const HowToCheckDatas = [
  {
    title: '문진',
    description: '병력 청취 및 생활 습관 파악',
    image: CheckImage0,
  },
  {
    title: '원인 검사',
    description: '초음파 검사, 암 검진',
    image: CheckImage1,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/0/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '약물 요법',
    description: '배란을 조절하는 호르몬제, 통증을 줄여주는 진통제 처방',
    image: TreatmentImage0,
  },
  {
    title: '원인 질환 치료',
    description: '통증을 유발하는 원인 질환을 찾아 치료 진행',
    image: TreatmentImage1,
  },
];

function Disease1(): ReactElement {
  const menuIndex = 2;
  const subIndex = 1;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Dysmenorrhea
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경통/배란통
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            정상적으로 월경을 하다가 비정상적인 월경주기로 바뀐 경우는 일시적인 경우를 제외하고는
            <br />
            초음파검사를 하는 것이 필요합니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    월경기간 전후로 나타나는 주기적인 통증입니다.
                    <br />
                    특별한 질환이나 원인 없이 호르몬 과다 분비로 인해 나타나는 경우가 많고, 통증이 극심할 경우 일상생활에도 큰 영향을 끼치게
                    됩니다.
                    <br />
                    통증이 심하다면 아라산부인과와 삼담을 받아보세요.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경통의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={58} />

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBox width={128} height={10}>
                  <Common.Fill color={Colors.gold200} />
                </Common.SizedBox>
                <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                  일차성(원발성) 월경통
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.SymptomDescriptionText>
                  병적 요인이 없는 생리통으로 보통 초경 1~2년 사이에 일반적으로 발생합니다.
                  <br />
                  생리 때 자궁에서 나오는 <mark>프로스타글라딘이 자궁 근육의 긴장도를 높여 강한 수축이 이뤄지면서 통증</mark>이 발생합니다.
                  <br />
                  주로 젊은 여성에게 발생하며 생리 시작 직전 혹은 직후에 시작해 2~3일 이내에 증상이 사라지는 특징이 있습니다.
                </Styled.SymptomDescriptionText>
                <Common.SizedBoxH height={48} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        자궁 수축과 혈류감소로 발생{' '}
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        생리시작 수시간 전 또는 직전에 발생되어 2~3일간 지속{' '}
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        심한 경우 구토, 설사를 동반{' '}
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={70} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              02
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={58} />

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBox width={128} height={10}>
                  <Common.Fill color={Colors.gold200} />
                </Common.SizedBox>
                <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                  이차성(속발성) 월경통
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.SymptomDescriptionText>
                  자궁근종, 자궁내막증, 자궁선근증 등 자궁 내 병적 요인이 생겨 발생하는데,
                  <br />
                  <mark>생리 시작 전 1~2주부터 나타나며 생리가 끝난 후에도 며칠간 통증</mark>이 지속합니다.
                  <br />
                  주로 초경 4년 이후에 발생합니다.{' '}
                </Styled.SymptomDescriptionText>
                <Common.SizedBoxH height={48} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.SizedBoxH height={1} />
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        골반에 병변이 있어 생기는 생리통으로 원인으로는 자궁내막증, 자궁선근증, 자궁근종
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.SizedBoxH height={1} />
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경통/배란통 검사 방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={63} />

          <Common.FlexRow justifyContent="center" alignItems="center">
            {HowToCheckDatas.map((e, i) => (
              <>
                <Common.SizedBoxW width={10} key={`${e.title}0`} />
                <Styled.CheckUpContainer backgroundImage={e.image} key={`${e.title}1`}>
                  <Typo.Pretendard_Medium fontSize={30} color={Colors.white}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={11} />
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.white}>
                    {e.description}
                  </Typo.Pretendard_Regular>
                </Styled.CheckUpContainer>
                <Common.SizedBoxW width={10} key={`${e.title}2`} />
              </>
            ))}
          </Common.FlexRow>
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            월경통/배란통 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={13} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content3>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease1;
