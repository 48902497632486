import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './main.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { WindowContext } from 'context/windowContext';
// import ReactPlayer from 'react-player';

import MainVideo from 'video/main.mp4';

import Header from 'components/header';
import Footer from 'components/footer';
import { Colors } from 'common';
import { Icons } from 'common/images';

import Notice from 'common/notice';

import AraClinicText from 'img/main/AraClinicText.png';

import SolutionImage0 from 'img/main/0.png';
import SolutionImage1 from 'img/main/1.png';
import SolutionImage2 from 'img/main/2.png';
import SolutionImage3 from 'img/main/3.png';

const MainCards: MainCardData[] = [
  {
    title: '여성검진',
    description: '꼼꼼한 여성 검진으로\n질환을 조기에 발견하세요.',
    image: SolutionImage0,
    link: '/checkup/0',
  },
  {
    title: '여성질환',
    description: '여성을 괴롭히는 각종 질환을 전문\n의의 정확한 진단으로 치료하세요.',
    image: SolutionImage1,
    link: '/disease/0',
  },
  {
    title: '갱년기 검진',
    description: '제2의 인생의 시작을\n검진으로 시작해보세요.',
    image: SolutionImage2,
    link: '/checkup/2',
  },
  {
    title: '질스케일링',
    description: '질 내 환경을 개선해\n각종 여성질환을 예방합니다.',
    image: SolutionImage3,
    link: '/aracare/4',
  },
];

const ServiceDescriptions = [
  '환자의 편의를 위해서 아라산부인과에서는  1인 회복실에서 편안하고 아늑함을 느껴보세요. ',
  '환자들의 개인정보보호를 원칙으로 진료 기록 및 내원 등 모든 부분에 있어 절대 비밀을 보장합니다.',
  '여성들의 건강 뿐만 아니라 마음까지 치유해드리기 위해 보다 친절한 상담을 약속 드립니다.',
];

function Main(): ReactElement {
  const { width, windowSize, scrollPosition } = useContext(WindowContext);

  const { videoWidth, videoHeight } = useMemo(() => {
    const ratio = windowSize.width / windowSize.height;
    if (ratio > 1920 / 1200) {
      return {
        videoWidth: windowSize.width,
        videoHeight: (windowSize.width * 1200) / 1920,
      };
    } else {
      return {
        videoWidth: (windowSize.height * 1920) / 1200,
        videoHeight: windowSize.height,
      };
    }
  }, [windowSize]);

  const [showNotice, setShowNotice] = useState<boolean[]>([]);

  useEffect(() => {
    const show = Notice.map((e) => {
      const time = localStorage.getItem(`show${e.id}`);

      if (!time) return true;

      console.log(time);
      if (time !== dayjs(Date.now()).format('YY-MM-DD')) {
        return true;
      }
      return false;
    });

    setShowNotice(show);
  }, []);

  const doNotShowAgain = (index: number) => {
    var notice = Notice[index];
    const now = dayjs(Date.now()).format('YY-MM-DD');
    localStorage.setItem(`show${notice.id}`, now);
    closeNotice(index);
  };

  const closeNotice = (index: number) => {
    setShowNotice((data) => {
      data[index] = false;
      return [...data];
    });
  };

  return (
    <>
      <Header
        color={scrollPosition > 0 ? Colors.black : Colors.white}
        backgroundColor={scrollPosition > 0 ? Colors.white : 'transparent'}
      />
      <Styled.Container>
        <Styled.MainViewport width={windowSize.width} height={windowSize.height}>
          <Styled.MainVideo>
            <video muted autoPlay loop width={Math.max(windowSize.width, (windowSize.height * 1920) / 1200)}>
              <source src={MainVideo} type="video/mp4" />
            </video>
          </Styled.MainVideo>
          <Styled.MainViedoTextArea>
            <Typo.Pretendard_Medium fontSize={30} color={Colors.white}>
              내 마음을 깊이 알아주는 곳
              <br />내 불편함을 알아주는 곳
            </Typo.Pretendard_Medium>
            <Common.SizedBoxH height={20} />
            <Common.SizedBox width={100} height={1}>
              <Common.Fill color={Colors.white} />
            </Common.SizedBox>
            <Common.SizedBoxH height={20} />
            <Typo.Pretendard_Regular fontSize={15} color={Colors.white}>
              최선을 다하겠습니다.
              <br />- 아라산부인과 -
            </Typo.Pretendard_Regular>
          </Styled.MainViedoTextArea>
        </Styled.MainViewport>

        <Styled.Solutions>
          <Common.SizedImage width={29.5} height={56} src={Icons.ArrowLeft} />
          <Common.FlexColumn alignItems="flex-start" justifyContent="flex-start">
            <Typo.Pretendard_Light fontSize={50} color={Colors.black}>
              아라산부인과
            </Typo.Pretendard_Light>
            <Common.SizedBoxH height={6} />
            <Typo.Baskerville_Regular fontSize={70} color={Colors.black}>
              BEST SOLUTION
            </Typo.Baskerville_Regular>
            <Common.SizedBoxH height={53} />

            <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
              {MainCards.map((e, i) => (
                <SolutionCard data={e} key={i} />
              ))}
            </Common.FlexRow>
          </Common.FlexColumn>
          <Common.SizedImage width={29.5} height={56} src={Icons.ArrowRight} />
        </Styled.Solutions>

        <Styled.Services>
          <Common.SizedImage width={414} height={20} src={AraClinicText} />
          <Common.SizedBoxH height={11} />
          <Typo.Pretendard_ExtraLight fontSize={50} color={Colors.black}>
            환자를 먼저 생각하는
          </Typo.Pretendard_ExtraLight>
          <Common.FlexRow>
            <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
              아라산부인과&nbsp;
            </Typo.Pretendard_Medium>
            <Typo.Pretendard_ExtraLight fontSize={50} color={Colors.black}>
              친절 의료 서비스
            </Typo.Pretendard_ExtraLight>
          </Common.FlexRow>

          <Common.SizedBoxH height={26} />

          <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
            {ServiceDescriptions.map((e, i) => (
              <ServiceCard description={e} index={i} key={i} />
            ))}
          </Common.FlexRow>
        </Styled.Services>
      </Styled.Container>

      {Notice.map(
        (e, i) =>
          showNotice[i] && (
            <Styled.ModalContainer>
              <img
                src={e.image}
                style={{
                  objectFit: 'contain',
                  width: `${e.width ?? '400px'}`,
                  height: `${e.height ?? 'auto'}`,
                }}
              />

              <Common.SizedBoxH height={10} />

              <Common.FlexRow width={400} justifyContent="space-between">
                <Common.NoOpacityButton onClick={(e) => doNotShowAgain(i)}>
                  <Typo.Pretendard_Light fontSize={14} color={Colors.white}>
                    오늘 하루 그만 보기
                  </Typo.Pretendard_Light>
                </Common.NoOpacityButton>

                <Common.NoOpacityButton onClick={(e) => closeNotice(i)}>
                  <Typo.Pretendard_Light fontSize={14} color={Colors.white}>
                    닫기
                  </Typo.Pretendard_Light>
                </Common.NoOpacityButton>
              </Common.FlexRow>
            </Styled.ModalContainer>
          ),
      )}

      <Footer />
    </>
  );
}

import MainNext from 'img/main/Next.svg';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

function SolutionCard(props: { data: MainCardData }): ReactElement {
  const { title, description, image, link } = props.data;
  return (
    <Styled.SolutionCard>
      <Common.SizedImage src={image} width={275} height={320} objectFit="cover" />
      <Styled.SolutionCardDescription>
        <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.black}>
          {title}
        </Typo.Pretendard_Medium>
        <Typo.Pretendard_Regular textAlign="left" fontSize={17} color={Colors.gray200}>
          {description}
        </Typo.Pretendard_Regular>
        <Link to={link}>
          <Common.SizedImage src={MainNext} width={20} height={20} />
        </Link>
      </Styled.SolutionCardDescription>
    </Styled.SolutionCard>
  );
}

export default Main;

function ServiceCard(props: { index: number; description: string }): ReactElement {
  return (
    <Styled.ServiceCard>
      <Typo.BodoniModa_Italic fontSize={50} color={Colors.white}>
        0{props.index + 1}
      </Typo.BodoniModa_Italic>
      <Common.SizedBox width={333} height={1}>
        <Common.Fill color={Colors.white} />
      </Common.SizedBox>
      <Typo.Pretendard_Light fontSize={16} color={Colors.white} textAlign="left">
        {props.description}
      </Typo.Pretendard_Light>
    </Styled.ServiceCard>
  );
}
