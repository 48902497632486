import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 300px;

  background: linear-gradient(180deg, #ebe2cc 0%, #f1e4c4 29.69%, rgba(235, 226, 204, 0.47) 100%);

  background-size: 100% 500px;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 98px 0 100px;

  background-color: ${Colors.white};
`;
