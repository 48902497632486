import Notice0 from 'img/notice/0.jpg';
import Notice1 from 'img/notice/1.jpg';
import Notice2 from 'img/notice/2.jpg';

const Notice = [
  {
    image: Notice1,
    id: '1',
    width: '600px',
  },
  {
    image: Notice0,
    id: '0',
  },
  {
    image: Notice2,
    id: '2',
    width: '100%',
    height: 'calc(100% - 50px)',
  },
];

export default Notice;
