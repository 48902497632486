import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease6.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/6/banner.png';
import CheckImage from 'img/photos/disease/4/check.png';

const CauseDatas = [
  '비만(체지방증가)으로 인한 경우',
  '인슐린저항성으로 인한 경우',
  '남성 호르몬이 과다하게 나오는 경우',
  '유전으로 인한 경우',
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/2/check_2.png';

const CheckUpDatas = [
  {
    title: '초음파 검사',
    image: CheckImage0,
  },
  {
    title: '혈액 검사',
    image: CheckImage1,
  },
  {
    title: '호르몬 검사',
    image: CheckImage2,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '호르몬 요법',
    description: '남성 호르몬 분비를 줄이는 피임약, 호르몬제 복용',
    image: TreatmentImage0,
  },
  {
    title: '체중 조절',
    description: '체중 조절을 통해 발병 가능성을 낮춤',
    image: TreatmentImage1,
  },
];

function Disease6(): ReactElement {
  const menuIndex = 2;
  const subIndex = 6;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            PolyCystic Ovary Syndrome
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            다낭성난소증후군
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            부인과적 내분비 질환의 80% 이상이 만성적인 무배란증에 기인하며
            <br />
            만성 무배란을 일으키는 가장 흔한 질환이 다낭성 난소증후군입니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    한달에 한 개씩 배란되어야 하는 난포가 정상적으로 배란되지 못하고 쌓여 정상 범위보다 많이 존재하게 되는 질환입니다.
                    <br />
                    뚜렷한 원인이 밝혀지지는 않았으나 무월경, 난임등 다양한 이상한 증상을 유발해 치료가 필요합니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            다낭성난소증후군 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={20} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            다낭성난소증후군의 원인은 명확하게 밝혀진 바는 없으나, 대체적으로 아래와 같은 이유로 발병한다고 보고 있습니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={46} />

          <Styled.CauseConatiner>
            {CauseDatas.map((e, i) => (
              <>
                <Common.FlexRow alignItems="center">
                  <Styled.CheckContainer>
                    <Styled.CheckImageContainer src={CheckImage} />
                  </Styled.CheckContainer>
                  <Common.SizedBoxW width={33} />
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200} textAlign="left">
                    {e}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>

                <Common.SizedBoxH height={12} />

                <Styled.CauseDash />

                {i < CauseDatas.length - 1 && <Common.SizedBoxH height={23} />}
              </>
            ))}
          </Styled.CauseConatiner>

          <Common.SizedBoxH height={69} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            다낭성난소증후군 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={44} />
                <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={20} color={Colors.gray200}>
                  다낭성난소증후군을 제때 치료하지 않고 방치하면 불임, 난임 등을 초래할 수 있습니다.
                  <br />
                  아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.
                </Typo.Pretendard_Regular>
                <Common.SizedBoxH height={55} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        생리주기 불규칙/무월경
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        얼굴과 몸에 여드름이 많이 난다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        갑자기 체중이 많이 증가했다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        4.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        몸에 털이 많이 난다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={103} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            다낭성난소증후군 검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={63} />

          <Common.FlexRow justifyContent="center" alignItems="center">
            {CheckUpDatas.map((e, i) => (
              <>
                <Common.SizedBoxW width={10} key={`${e.title}0`} />
                <Styled.CheckUpContainer backgroundImage={e.image} key={`${e.title}1`}>
                  <Typo.Pretendard_Medium fontSize={30} color={Colors.white}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                </Styled.CheckUpContainer>
                <Common.SizedBoxW width={10} key={`${e.title}2`} />
              </>
            ))}
          </Common.FlexRow>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            다낭성난소증후군 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={23} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content2>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease6;
