import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease0.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import DescriptionImage from 'img/photos/disease/0/description.png';

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/0/check_2.png';

const HowToCheckDatas = [
  {
    title: '문진',
    description: '병력 청취 및 생활 습관 파악',
    image: CheckImage0,
  },
  {
    title: '원인 검사',
    description: '초음파 검사, 암 검진',
    image: CheckImage1,
  },
  {
    title: '각종 정밀 검사',
    description: '호르몬 검사 등',
    image: CheckImage2,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/0/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '호르몬 요법',
    description: '월경 주기와 출혈량 조절을 위해\n호르몬제, 호르몬 주사, 호르몬 분비 장치 등을 이용합니다.',
    image: TreatmentImage0,
  },
  {
    title: '수술 요법',
    description: '자궁내 소파술 및 고주파 기계를 이용하여 지혈을 통해\n비정상 출혈을 조절 합니다.',
    image: TreatmentImage1,
  },
];

function Disease0(): ReactElement {
  const menuIndex = 2;
  const subIndex = 0;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Irregular Menstruation
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            비정상출혈/생리불순
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            정상적으로 월경을 하다가 비정상적인 월경주기로 바뀐 경우는 일시적인 경우를 제외하고는
            <br />
            초음파검사와 자궁경부암검사, 필요시 호르몬 검사를 하는 것이 필요합니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={DescriptionImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    생리 기간과 상관 없이 출혈이 발생하는 경우를 말합니다.
                    <br />
                    보통 여성의 정상 월경 주기는 21~40일 정도입니다.
                    <br />
                    생리는 여성의 건강을 나타내는 지표이기 때문에 정상적인 주기를 벗어났다면 일시적인 경우를 제외하고는 시기를 놓치지 말고
                    산부인과 전문의를 찾는 것이 좋습니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            비정상출혈/생리불순원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={58} />

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBox width={128} height={10}>
                  <Common.Fill color={Colors.gold200} />
                </Common.SizedBox>
                <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                  비정상출혈
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.SymptomDescriptionText>
                  비정상출혈은
                  <mark>
                    자궁근종, 난소종양, 자궁암, 난소암 등 종양 있는 경우나
                    <br />
                    경구피임 약 부작용, 자연유산, 자궁 외 임신, 극심한 스트레스, 체중변화 등
                    <br />
                    다양한 원인
                  </mark>
                  이 있어 현재 연령이나 신체 면역 상태 등을 종합적으로 고려하여 진단합니다.{' '}
                </Styled.SymptomDescriptionText>
                <Common.SizedBoxH height={48} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        비정상출혈은 자궁근종, 난소종양, 자궁암, 난소암 등 종양 있는 경우나
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        경구피임 약 부작용, 자연유산, 자궁 외 임신, 극심한 스트레스, 체중변화 등
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        다양한 원인이 있어 현재 연령이나 신체 면역 상태 등을 종합적으로 고려하여 진단합니다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={70} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              02
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.SizedBoxH height={58} />

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBox width={128} height={10}>
                  <Common.Fill color={Colors.gold200} />
                </Common.SizedBox>
                <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                  생리불순
                </Typo.Pretendard_Medium>
                <Common.SizedBoxH height={20} />
                <Styled.SymptomDescriptionText>
                  정상적인 생리주기는 20-90일 입니다.
                  <br />
                  생리불순은{' '}
                  <mark>
                    영양섭취가 고르지 못한 경우, 내분비계의 이상, 극심한 스트레스, 체중변화 등
                    <br />
                    다양한 원인
                  </mark>
                  이 있으며, 가장 많은 원인이 배란 장애이므로 여성의 신체에 이상이 생겼다는 것을 의미합니다.
                  <br />
                  오랜기간 방치하면 골다공증, 우울증, 불임 등의 이차적인 질환을 초래해 치료가 중요합니다.
                </Styled.SymptomDescriptionText>
                <Common.SizedBoxH height={48} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        불규칙한 생리 - 보름마다 생리를 할 경우
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        세달이상 생리를 하지 않을 경우
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        다양한 원인이 있어 현재 연령이나 신체 면역 상태 등을 종합적으로 고려하여 진단합니다.
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            비정상출혈/생리불순 검사 방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={63} />

          <Common.FlexRow justifyContent="center" alignItems="center">
            {HowToCheckDatas.map((e, i) => (
              <>
                <Common.SizedBoxW width={10} key={`${e.title}0`} />
                <Styled.CheckUpContainer backgroundImage={e.image} key={`${e.title}1`}>
                  <Typo.Pretendard_Medium fontSize={30} color={Colors.white}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={11} />
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.white}>
                    {e.description}
                  </Typo.Pretendard_Regular>
                </Styled.CheckUpContainer>
                <Common.SizedBoxW width={10} key={`${e.title}2`} />
              </>
            ))}
          </Common.FlexRow>
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            비정상출혈/생리불순 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={13} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content3>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease0;
