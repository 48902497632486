import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease5.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/5/banner.png';
import CheckImage from 'img/photos/disease/4/check.png';

const CauseDatas = ['성관계로 인한 세균 감염', '면역력이 저하된 경우'];

import WhyImage0 from 'img/photos/disease/5/why_0.png';
import WhyImage1 from 'img/photos/disease/5/why_1.png';
import WhyImage2 from 'img/photos/disease/5/why_2.png';

const WhyDatas = [
  {
    title: '난관 유착 및 난관 폐쇄',
    content: '난관으로 염증이 퍼져 난관이\n좁아지거나 막혀 자궁 외 임신을\n겪거나 불임의 원인이 됩니다.',
    image: WhyImage0,
    top: 165,
    left: 141,
  },
  {
    title: '난관 수종',
    content: '난관에 퍼진 염증으로 내강에\n액체가 차올라 복통을 일으키고\n질 분비물이 늘어납니다.',
    image: WhyImage1,
    top: 165,
    left: 136,
  },
  {
    title: '만성적인 골반통',
    content: '월경 주기와 상관없이 골반 및\n하복부 통증이 지속됩니다.',
    image: WhyImage2,
    top: 150,
    left: 107,
  },
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';
import DownArrow from 'img/photos/disease/DownArrow.png';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '약물 요법',
    description: '원인균을 제거하는 항생제 처방으로 증상을 호전 시킵니다.',
    image: TreatmentImage0,
  },
  {
    title: '주사 치료',
    description: '항셍제 주사, 근육 내 주사를 처방해 증상을 호전 시킵니다.',
    image: TreatmentImage1,
  },
];

function Disease5(): ReactElement {
  const menuIndex = 2;
  const subIndex = 5;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Pelvic Inflammatory Disease
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            골반염
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            방치하면 불임을 유발할 수 있어
            <br />
            반드시 주의가 필요합니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    자궁 안과 바깥에 번식하고 있던 세균이 자궁내막, 나팔관, 복강까지 퍼져 염증을 일으키는 질환입니다.
                    <br />
                    대개 골반염을 일으키는 균이 자궁을 통해 위로 올라와 발생합니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            골반염 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={60} />

          <Styled.CauseConatiner>
            {CauseDatas.map((e, i) => (
              <>
                <Common.FlexRow alignItems="center">
                  <Styled.CheckContainer>
                    <Styled.CheckImageContainer src={CheckImage} />
                  </Styled.CheckContainer>
                  <Common.SizedBoxW width={33} />
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200} textAlign="left">
                    {e}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>

                <Common.SizedBoxH height={12} />

                <Styled.CauseDash />

                {i < CauseDatas.length - 1 && <Common.SizedBoxH height={23} />}
              </>
            ))}
          </Styled.CauseConatiner>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Why
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            골반염 왜 치료해야 할까?
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={60} />

          <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
            {WhyDatas.map((e) => (
              <Styled.WhyContainer key={e.title}>
                <Styled.WhyViewport>
                  <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray300}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={9} />
                  <Typo.Pretendard_Regular textAlign="left" fontSize={20} lineHeight={30} color="#958E78">
                    {e.content}
                  </Typo.Pretendard_Regular>
                </Styled.WhyViewport>
                <Styled.WhyImageContainer src={e.image} top={e.top} left={e.left} />
              </Styled.WhyContainer>
            ))}
          </Common.FlexRow>

          <Common.SizedBoxH height={67} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            골반염 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={44} />
                <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={20} color={Colors.gray200}>
                  골반염을 제때 치료하지 않고 방치하면 자궁 외 임신, 불임 등으로 진행될 수 있습니다.
                  <br />
                  아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.
                </Typo.Pretendard_Regular>
                <Common.SizedBoxH height={55} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        하복부 통증
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        골반 및 허리 통증
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        성관계 시 통증
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        4.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        38도 이상의 고열
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            아라산부인과 골반염 검사
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={100} height={100} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={20} lineHeight={30} color={Colors.gray300}>
            원인균을 파악하는 균검사와 염증 정도를 파악하는 혈액 검사를 진행하며
            <br />
            펼요한 경우 난소와 난관 상태 파악을 위해 초음파 검사를 진행하기도 합니다.
            <br />
            골반염은 재발이 쉽다는 특성이 있어 정확한 원인 파악과
            <br />
            체계적인 치료 계획 수립이 무엇보다 중요합니다.
          </Typo.Pretendard_Regular>
        </Styled.Content3>

        <Styled.Content4>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            골반염 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={23} />

          {TreatmentDatas.map((e, i) => (
            <div key={e.title}>
              <Common.SizedBoxH height={40} />
              <Styled.TreatmentContainer>
                <Styled.TreatmentIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Treat
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.TreatmentIndexContainer>
                <Styled.TreatmentContainerViewport>
                  <Common.SizedImage width={295} height={200} src={e.image} />
                  <Common.SizedBoxW width={52} />

                  <Common.FlexColumn>
                    <Common.SizedBoxH height={44} />
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray200}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} color={Colors.gray200}>
                      {e.description}
                    </Typo.Pretendard_Regular>
                  </Common.FlexColumn>
                </Styled.TreatmentContainerViewport>
              </Styled.TreatmentContainer>
            </div>
          ))}
        </Styled.Content4>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease5;
