import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './pregnant0.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { WindowContext } from 'context/windowContext';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

// images
import PrenatalTestPhoto from 'img/photos/pregnant/0/prenatal_test_0.png';
import CheckUpPhoto0 from 'img/photos/pregnant/0/check_up_0.png';
import CheckUpPhoto1 from 'img/photos/pregnant/0/check_up_1.png';
import CheckUpPhoto2 from 'img/photos/pregnant/0/check_up_2.png';
import CheckUpPhoto3 from 'img/photos/pregnant/0/check_up_3.png';

const CheckUpCardDatas = [
  {
    title: '01 병력청취',
    image: CheckUpPhoto0,
  },
  {
    title: '02 혈압 및 체중 측정',
    image: CheckUpPhoto1,
  },
  {
    title: '03 초음파 검사',
    image: CheckUpPhoto2,
  },
  {
    title: '04 임신 초기 종합 검사',
    image: CheckUpPhoto3,
  },
];

const RequiredTestDatas = [
  {
    title: '01 임신 확인 후 첫 내원',
    content: '· 초음파(임신낭 확인)\n· 임신 초기 혈액 검사\n· 자궁경부암, 질 분비물 균 검사',
  },
  {
    title: '02 임신 10 - 14주',
    content: '· 초음파 : 태아 목덜미 투명대\n (Nuchal Translucency)측정\n· 산전 기형아 검사\n - 1차 통합 검사(Integrated 1차)\n - NIPT',
  },
  {
    title: '03 임신 15 - 22주',
    content: '· 산전 기형아 검사\n -2차 통합 검사\n (Integrated 2차)',
  },
  {
    title: '04 임신 20-25주',
    content: '· 정밀 초음파\n· 임신성 당뇨 선별검사\n· 혈액(빈혈 유 •무)및 소변 검사',
  },
];

function Pregnant0(): ReactElement {
  const menuIndex = 1;
  const subIndex = 0;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Contents>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Prenatal tests
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            산전검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            임신 중에 산모와 태아의 상태를 검사하기 위해 시행하며 임신 주수마다 정해진 검사를 시행하게 됩니다.
            <br />
            아이의 발육상태 및 산모의 건강, 질병 유무를 꼼꼼하게 살펴봅니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={58} />

          <Common.FlexRow>
            <Common.SizedImage src={PrenatalTestPhoto} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    건강하고 총명한 아기의 출산을 위해 무엇을 어떻게 준비해야 하는지 미리 알아두는 것이 중요합니다.
                    <br /> 초기에 시작되는 산전검사는 산모의 몸 상태 체크 및 차후 기형아 발생을 간접적으로 확인하기 위해 시행되는 검사들로
                    특별한 금식 없이 검사가 가능합니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            검사안내
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={51} />

          <Common.FlexRow alignItems="center" justifyContent="space-between" width={1160}>
            {CheckUpCardDatas.map((e, i) => (
              <Common.FlexColumn alignItems="center" key={e.title}>
                <Common.SizedImage src={e.image} width={275} height={200} />

                <Common.SizedBox width={275} height={50}>
                  <Common.Fill color={Colors.gold200}>
                    <Common.FlexRow width={275} height={50} alignItems="center" justifyContent="center">
                      <Typo.Pretendard_Regular textAlign="left" width={235} fontSize={20} color={Colors.gray300}>
                        {e.title}
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Common.Fill>
                </Common.SizedBox>
              </Common.FlexColumn>
            ))}
          </Common.FlexRow>

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Required test by number of weeks of pregnancy
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            임신 주수별 필요 검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={71} />

          <Common.FlexRow alignItems="center" justifyContent="space-between" width={1160}>
            {RequiredTestDatas.map((e, i) => (
              <Common.FlexColumn alignItems="center" key={e.title}>
                <Styled.RoundedCard>
                  <Typo.Pretendard_Regular lineHeight={26} textAlign="left" fontSize={18} color={Colors.gray200}>
                    {e.content}
                  </Typo.Pretendard_Regular>
                </Styled.RoundedCard>

                <Common.SizedBox width={275} height={50}>
                  <Common.Fill color={Colors.gold200}>
                    <Common.FlexRow width={275} height={50} alignItems="center" justifyContent="center">
                      <Typo.Pretendard_Regular textAlign="left" width={235} fontSize={20} color={Colors.gray300}>
                        {e.title}
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Common.Fill>
                </Common.SizedBox>
              </Common.FlexColumn>
            ))}
          </Common.FlexRow>
        </Styled.Contents>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Pregnant0;
