import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 120px;
`;

export const RoundedCard = styled.div`
  width: 275px;
  height: 180px;

  border-radius: 0px 100px 0px 0px;
  background: #f8f8f8;

  padding: 31px 0 0 19px;
`;
