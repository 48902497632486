import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import BackgroundImage from 'img/photos/aracare/2/background.png';
import CauseContainerImage from 'img/photos/disease/4/cause_container.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 100px;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 98px 0 28px;

  background-color: ${Colors.gold100};
`;

export const SolutionCard = styled.div`
  width: 964px;

  border-radius: 20px;
  background: var(--ffffff, #fff);

  position: relative;
`;

export const SolutionCardViewport = styled.div`
  border-radius: 20px;

  display: flex;
  align-items: stretch;

  width: 100%;
  overflow: hidden;
`;

export const SolutionIconContainer = styled.div`
  width: 295px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SolutionCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(235, 226, 204, 0) 0%,
      rgba(235, 226, 204, 0.15) 58.85%,
      rgba(235, 226, 204, 0.7) 100%
    ),
    url(<path-to-image>), lightgray 0px 74.867px / 103.051% 60.646% no-repeat;
`;

export const SolutionCardDescription = styled.div`
  width: 669px;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 44px 0 37px 51px;
`;

export const SolutionIndexContainer = styled.div`
  width: 93px;
  height: 93px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -53px;
  top: -30px;

  z-index: 1;
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;

  padding: 52px 0 72px;

  background-image: url(${BackgroundImage});
  background-size: cover;
`;

export const Content3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 99px 0 100px;
`;

export const CauseConatiner = styled.div`
  width: 794px;
  min-height: 319px;

  background-image: url(${CauseContainerImage});
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  transform: translateX(10px);

  padding: 91px 123px 130px 101px;
`;

export const CheckContainer = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid var(--665-e-4-b, #665e4b);

  position: relative;
`;

export const CheckImageContainer = styled.img`
  width: 35px;
  height: 34px;
  position: absolute;
  right: -7px;
  top: -17px;
`;

export const CauseDash = styled.div`
  width: 100%;
  height: 0px;
  border: 0.5px dashed ${Colors.black};
`;
