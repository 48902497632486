export default {
  // basic
  white: '#ffffff',
  black: '#000000',

  // gold
  gold100: '#FFF9EA',
  gold200: '#EBE2CC',
  gold300: '#CE9715',

  // gray
  gray100: '#F3F3F3',
  gray200: '#888888',
  gray300: '#665E4B',
};
