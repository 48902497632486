import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './aracare2.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import Banner from 'img/photos/aracare/2/background.png';

import Icon0 from 'img/photos/aracare/2/solution_0.jpg';
import Icon1 from 'img/photos/aracare/2/solution_1.jpg';

import OKImage from 'img/photos/aracare/2/Ok.png';

const Solution1Datas = [
  {
    title: '삭센다',
    content:
      '삭센다는 ‘라라글루티드’라는 성분의 비만 치료 주사입니다.\n뇌에서는 식욕 억제를, 위에서는 위장 운동을 저하시켜 포만감을 증가\n시킵니다. 하루에 한 번 자가 투여하는 방식으로 간편하게 체중 관리를\n할 수 있습니다.',
    icon: Icon0,
    subContents: ['내장지방 감소', '식욕 억제', '혈당 조절', 'FDA 승인 주사제'],
  },
  {
    title: '큐시미아',
    content: '큐시미아는 펜터민, 토피라메이트 성분으로 구성된 비만약입니다.\n뇌가 배고픔을 느끼지 못하게 만들어 체중 감량을 돕습니다.',
    icon: Icon1,
    subContents: ['내장지방 감소', '식욕감소', '간편한 경구용 비만약', 'FDA 승인 주사제'],
  },
];

import DownArrow from 'img/photos/disease/DownArrow.png';

import CheckImage from 'img/photos/disease/4/check.png';

const RecommandDatas = [
  '혼자의 힘으로 다이어트를 하는 것이 힘드신분',
  '운동과 식단 관리로 빠지지 않는 군살이 고민이신 분',
  '복부, 허벅지 등 특정 부위에 살이 집중되어 있는 분',
  '출산 후 혹은 갱년기에 급격하게 체중이 늘어난 분',
];

function AraCare2(): ReactElement {
  // const menuIndex = 4;
  // const subIndex = 2;
  const menuIndex = 3;
  const subIndex = 1;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />

      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Obesity
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            비만
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            혼자 힘으로 다이어트가 어렵다면, 아라산부인과가 도와드리겠습니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={89} />

          <Common.FlexRow>
            <Common.SizedImage src={Banner} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    현대인들이 평생 달고 살아야 하는 숙제와도 같습니다.
                    <br />
                    적정 체중을 넘어선 채로 장기간 유지하다 보면 고지혈증, 당뇨병 등 다양한 질환을 초래할 가능성이 매우 높아지기 때문에
                    관리가 필요합니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Solution
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            호르몬 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={53} />

          {Solution1Datas.map((e, i) => (
            <div key={e.title}>
              <Styled.SolutionCard>
                <Styled.SolutionIndexContainer>
                  <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                    Solution
                  </Typo.Pretendard_Light>
                  <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                    0{i + 1}
                  </Typo.Pretendard_Regular>
                </Styled.SolutionIndexContainer>

                <Styled.SolutionCardViewport>
                  <Styled.SolutionIconContainer>
                    <Styled.SolutionCardImage src={e.icon} />
                  </Styled.SolutionIconContainer>

                  <Styled.SolutionCardDescription>
                    <Typo.Pretendard_Medium textAlign="left" fontSize={30} color={Colors.gray300}>
                      {e.title}
                    </Typo.Pretendard_Medium>

                    <Common.SizedBoxH height={20} />

                    <Typo.Pretendard_Regular textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray200}>
                      {e.content}
                    </Typo.Pretendard_Regular>

                    <Common.SizedBoxH height={12} />
                    <Common.Span />

                    <Common.FlexRow>
                      {e.subContents.map((sub, i) => (
                        <>
                          <Common.FlexColumn>
                            <Common.SizedImage src={OKImage} width={30} height={30} />
                            <Typo.Pretendard_Regular fontSize={20} color={Colors.gray300}>
                              {sub}
                            </Typo.Pretendard_Regular>
                          </Common.FlexColumn>
                          {i < e.subContents.length - 1 && <Common.SizedBoxW width={20} />}
                        </>
                      ))}
                    </Common.FlexRow>
                  </Styled.SolutionCardDescription>
                </Styled.SolutionCardViewport>
              </Styled.SolutionCard>

              <Common.SizedBoxH height={60} />
            </div>
          ))}
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Point
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            아라산부인과 비만 클리닉 key point
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={100} height={100} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={20} lineHeight={30} color={Colors.gray300}>
            여성 비만의 원인은 식습관 및 생활습관의 문제 뿐만 아니라, 여성 질환이 원인일 경우가 많습니다.
            <br />
            따라서 단순히 체중 감량만을 목표로 두는 것이 아니라
            <br />
            질환 발생하였을 가능성을 열어 두고 꼼꼼한 진단을 하는 것이 중요합니다.
            <br />
            <br />
            아라산부인과는 산부인과 전문의의 진단을 통해 비만의 원인을 파악하고,
            <br />
            생리불순, 부정출혈 등 체중감량으로 인해 발생할 수 있는 각종 부작용을 예방합니다.
          </Typo.Pretendard_Regular>
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Who
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            비만 클리닉 추천 대상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={19} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            이런 분들에게 추천을 드립니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={47} />

          <Styled.CauseConatiner>
            {RecommandDatas.map((e, i) => (
              <>
                <Common.FlexRow alignItems="center">
                  <Styled.CheckContainer>
                    <Styled.CheckImageContainer src={CheckImage} />
                  </Styled.CheckContainer>
                  <Common.SizedBoxW width={33} />
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200} textAlign="left">
                    {e}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>

                <Common.SizedBoxH height={12} />

                <Styled.CauseDash />

                {i < RecommandDatas.length - 1 && <Common.SizedBoxH height={23} />}
              </>
            ))}
          </Styled.CauseConatiner>
        </Styled.Content3>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default AraCare2;
