import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomBalloonImage from 'img/photos/disease/0/SymptomBalloon.svg';
import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: 70px 0 0;
`;

export const Content0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 93px 0 83px;
`;

export const TestTypeTitle = styled.div`
  width: 177px;
  height: 270px;
  border-radius: 10px;
  background: var(--ebe-2-cc, #ebe2cc);

  display: flex;
  align-items: center;

  flex-direction: column;
`;

export const TestTypeContents = styled.div`
  width: 963px;
  height: 270px;

  border-radius: 10px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);

  padding: 27px 95px 27px 38px;
  grid-auto-flow: column;

  align-items: center;
`;

export const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;

  padding: 100px 0 145px;
`;

export const Content1Background = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 500px;
  background-color: ${Colors.gold100};

  z-index: -1;
`;

export const ProgramStepCard = styled.div`
  width: 373px;
  height: 411px;

  border-radius: 20px;

  display: flex;
  flex-direction: column;

  overflow: hidden;
`;
