import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './checkup2.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import Banner from 'img/photos/checkup/2/banner.png';

import SymptomImage0 from 'img/photos/disease/10/symptomImage/0.png';
import SymptomImage1 from 'img/photos/disease/10/symptomImage/1.png';
import SymptomImage2 from 'img/photos/disease/10/symptomImage/2.png';
import SymptomImage3 from 'img/photos/disease/10/symptomImage/3.png';
import SymptomImage4 from 'img/photos/disease/10/symptomImage/4.png';

const SymptomDatas = [
  {
    title: '안면홍조',
    content: '얼굴이 화끈거리며 빨갛게 달아오르고\n땀을 비오듯 흘리기도 합니다.',
    image: SymptomImage0,
  },
  {
    title: '정신적 불안정',
    content: '우울증, 정서불안, 신경과민,\n기억력 감소 등의 증상이 나타납니다.',
    image: SymptomImage1,
  },
  {
    title: '수면장애',
    content: '밤에 충분히 수면을 취하지 못하고\n만성피로, 두통을 유발합니다.',
    image: SymptomImage2,
  },
  {
    title: '피부 노화',
    content: '에스트로겐 분비가 감소해 피부 탄력이\n떨어지고 주름이 증가합니다.',
    image: SymptomImage3,
  },
  {
    title: '골다공증',
    content: '에스트로겐이 감소함에 따라 골흡수와 형성에\n불균형을 초래해 골다공증이 발생합니다.',
    image: SymptomImage4,
  },
];

const CheckUpTypeDatas = ['호르몬 검사', '초음파 검사', '콜레스테롤 검사', '간기능 검사', 'Vit D 검사', '자궁경부암 검사'];

import ProgramImage0 from 'img/photos/checkup/0/programImages/0.jpg';
import ProgramImage1 from 'img/photos/checkup/0/programImages/1.jpg';
import ProgramImage2 from 'img/photos/checkup/0/programImages/2.png';

const ProgramStepDatas = [
  {
    title: '검사 전 상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage0,
  },
  {
    title: '검사',
    content: '이러한 자료를 바탕으로 환자분 개개인에\n맞추어 필요한 예비부부 검사 프로그램을\n만들어 제시 해드립니다. ',
    image: ProgramImage1,
  },
  {
    title: '검사 후 결과상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage2,
  },
];

import StepBarImage from 'img/photos/checkup/0/bar.png';

function CheckUp2(): ReactElement {
  const menuIndex = 3;
  const subIndex = 2;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />

      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Obstetrics & Gynecology
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            갱년기검진
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            성폐경 및 갱년기는난소의 노화로 더이상
            <br />
            배란이 진행되지 않아 무월경이 1년이상 지속되는 것을 말합니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={59} />

          <Common.FlexRow>
            <Common.SizedImage src={Banner} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    갱년기 증상이 있으면서 3개월 이상 생리가 없으면 폐경 유무를 호르몬 검사를 통하여 확인 합니다.
                    <br />
                    호르몬 요법을 원하는 경우, 폐경 진단을 확인 하는 경우 등은 혈액 검사가 필요합니다.{' '}
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            갱년기 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={14} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            갱년기 증상을 이야기 합니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={27} />

          <Common.FlexRow width={1160} justifyContent="space-between">
            {SymptomDatas.slice(0, 3).map((e, i) => (
              <Common.FlexColumn width={373} alignItems="stretch">
                <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
                  0{i + 1}
                </Typo.BodoniMT_BoldItalic>
                <Common.SizedBox width={373} height={2}>
                  <Common.Fill color={Colors.gray200} />
                </Common.SizedBox>
                <Common.FlexRow>
                  <Common.SizedBoxW width={78} />
                  <Common.FlexColumn>
                    <Common.SizedImage width={295} height={240} src={e.image} />
                    <Common.SizedBoxH height={20} />
                    <Typo.Pretendard_Medium textAlign="left" color={Colors.gray300} fontSize={30}>
                      {e.title}
                    </Typo.Pretendard_Medium>
                    <Common.SizedBoxH height={6} />
                    <Typo.Pretendard_Light textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray200}>
                      {e.content}
                    </Typo.Pretendard_Light>
                  </Common.FlexColumn>
                </Common.FlexRow>
              </Common.FlexColumn>
            ))}
          </Common.FlexRow>
          <Common.FlexRow width={1160}>
            <Common.FlexColumn alignItems="flex-start">
              <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
                04
              </Typo.BodoniMT_BoldItalic>
              <Common.SizedBox width={373} height={2}>
                <Common.Fill color={Colors.gray200} />
              </Common.SizedBox>
              <Common.FlexRow>
                <Common.SizedBoxW width={78} />
                <Common.FlexColumn>
                  <Common.SizedImage width={295} height={240} src={SymptomDatas[3].image} />
                  <Common.SizedBoxH height={20} />
                  <Typo.Pretendard_Medium textAlign="left" color={Colors.gray300} fontSize={30}>
                    {SymptomDatas[3].title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={6} />
                  <Typo.Pretendard_Light textAlign="left" width={300} fontSize={20} lineHeight={30} color={Colors.gray200}>
                    {SymptomDatas[3].content}
                  </Typo.Pretendard_Light>
                </Common.FlexColumn>
              </Common.FlexRow>
            </Common.FlexColumn>

            <Common.SizedBoxW width={20} />

            <Common.FlexColumn width={766} alignItems="flex-start">
              <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
                05
              </Typo.BodoniMT_BoldItalic>
              <Common.SizedBox width={373} height={2}>
                <Common.Fill color={Colors.gray200} />
              </Common.SizedBox>
              <Common.FlexRow>
                <Common.SizedBoxW width={78} />
                <Common.FlexColumn alignItems="flex-start">
                  <Common.SizedImage width={295} height={240} src={SymptomDatas[4].image} />
                  <Common.SizedBoxH height={20} />
                  <Typo.Pretendard_Medium textAlign="left" color={Colors.gray300} fontSize={30}>
                    {SymptomDatas[4].title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={6} />
                  <Typo.Pretendard_Light textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray200}>
                    {SymptomDatas[4].content}
                  </Typo.Pretendard_Light>
                </Common.FlexColumn>
              </Common.FlexRow>
            </Common.FlexColumn>
          </Common.FlexRow>

          <Common.SizedBoxH height={103} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Test
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            검사항목
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={100} />

          <Common.FlexRow alignItems="center" justifyContent="space-between" width={1160}>
            <Styled.TestTypeTitle>
              <Common.SizedBoxH height={79} />
              <Typo.Pretendard_Regular height={40} fontSize={20} color={Colors.gray300}>
                갱년기 검진
              </Typo.Pretendard_Regular>
              <Common.SizedBoxH height={5} />
              <Typo.Pretendard_Regular lineHeight={25} fontSize={16} color={Colors.gray300}>
                인생 제2막을
                <br />
                위한 준비
              </Typo.Pretendard_Regular>
            </Styled.TestTypeTitle>

            <Styled.TestTypeContents>
              {CheckUpTypeDatas.map((e) => (
                <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={20}>
                  · {e}
                </Typo.Pretendard_Regular>
              ))}
            </Styled.TestTypeContents>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
            {ProgramStepDatas.map((e, i) => (
              <Styled.ProgramStepCard>
                <Common.SizedImage src={e.image} width={373} height={191} objectFit="cover" />
                <Common.SizedBox width={373} height={220}>
                  <Common.Fill color={Colors.white}>
                    <Common.FlexColumn alignItems="center">
                      <Common.SizedBoxH height={25} />
                      <Common.FlexRow>
                        <Typo.BodoniMT_BoldItalic fontSize={20} color={Colors.gray300}>
                          STEP 0{i + 1}
                        </Typo.BodoniMT_BoldItalic>
                        <Typo.Pretendard_Medium fontSize={20} color={Colors.gray300}>
                          {e.title}
                        </Typo.Pretendard_Medium>
                      </Common.FlexRow>
                      <Common.SizedBoxH height={10} />
                      <Typo.Pretendard_Regular fontSize={16} color={Colors.gray200} lineHeight={24}>
                        {e.content}
                      </Typo.Pretendard_Regular>
                    </Common.FlexColumn>
                  </Common.Fill>
                </Common.SizedBox>
              </Styled.ProgramStepCard>
            ))}
          </Common.FlexRow>

          <Common.SizedBoxH height={61} />

          <Common.FlexRow width={1160} alignItems="center" justifyContent="center">
            <Common.FlexColumn justifyContent="center">
              <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                난포자극호르몬
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular fontSize={16} color={Colors.black}>
                (FSH)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.SizedBoxW width={69} />

            <Common.FlexColumn justifyContent="center">
              <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                황체형성호르몬
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular fontSize={16} color={Colors.black}>
                (LH)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.SizedBoxW width={90} />

            <Common.FlexColumn justifyContent="center">
              <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                에스트라디올
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular fontSize={16} color={Colors.black}>
                (E2)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.SizedBoxW width={93} />

            <Common.FlexColumn justifyContent="center">
              <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                갑상선호르몬
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular fontSize={16} color={Colors.black}>
                (TSH)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.SizedBoxW width={112} />

            <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
              비타민D
            </Typo.Pretendard_Regular>

            <Common.SizedBoxW width={25} />
          </Common.FlexRow>
          <Common.SizedBoxH height={7} />
          <Common.SizedImage src={StepBarImage} width={1160} height={25} />

          <Styled.Content1Background />
        </Styled.Content1>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default CheckUp2;
