export { default as Main } from './main';
export { default as Header } from './header';
export { default as Footer } from './footer';

export { default as Abouts } from './about';
export { default as Pregnants } from './pregnant';
export { default as Diseases } from './disease';
export { default as CheckUps } from './checkup';
export { default as AraCares } from './aracare';

export { default as Uninsured } from './uninsured';
export { default as Rights } from './rights';

export { default as Inquire } from './inquire';
