import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease3.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/3/banner.png';

import TypeImage0 from 'img/photos/disease/3/type_0.png';
import TypeImage1 from 'img/photos/disease/3/type_1.png';

import CauseIcon0 from 'img/photos/disease/2/causeIcons/0.png';
import CauseIcon1 from 'img/photos/disease/2/causeIcons/1.png';
import CauseIcon2 from 'img/photos/disease/2/causeIcons/2.png';

const CauseDatas = [
  {
    title: '자궁 • 난소질환',
    contents: ['자궁내막증', '자궁내막, 경부 용종', '근종, 선근종', '자궁내막암, 경부암'],
    icon: CauseIcon0,
  },
  {
    title: '호르몬 이상',
    contents: ['조기폐경', '갑상선 질환', '고프로락틴혈증'],
    icon: CauseIcon1,
  },
  {
    title: '일시적 • 생리적 이상',
    contents: ['무배란', '스트레스', '약물'],
    icon: CauseIcon2,
  },
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';

const HowToCheckDatas = [
  {
    title: '혈액 검사',
    image: CheckImage0,
  },
  {
    title: '초음파 검사',
    image: CheckImage1,
  },
];

function Disease3(): ReactElement {
  const menuIndex = 2;
  const subIndex = 3;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />
      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Stomachache
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            난소혹
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            아라산부인과의 빠른 진단으로 안정적인 주기를 되찾으세요.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={89} />

          <Common.FlexRow>
            <Common.SizedImage src={BannerImage} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    단순 복통, 월경통으로 인한 것 뿐만 아니라
                    <br />
                    자궁근종, 자궁내막증, 난소낭종 등 다양한 여성 질환이 원인일 수 있습니다. 따라서 별다른 이유 없이 복통이 지속된다면
                    내원하여 명확한 원인을 파악해야 합니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>
        </Styled.Content0>

        <Styled.Content1>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            난소에 발생하는 혹(난소 꼬임)
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={90} />

          <Common.FlexRow width={1160} alignItems="flex-start" justifyContent="space-between">
            <Common.SizedImage src={TypeImage0} width={531} height={400} />
            <Common.FlexColumn>
              <Styled.Content1Title>
                <Typo.Pretendard_Regular fontSize={24} color={Colors.gray300}>
                  기능성 난소 낭종
                </Typo.Pretendard_Regular>
              </Styled.Content1Title>

              <Common.SizedBoxH height={24} />

              <Typo.Pretendard_Medium textAlign="left" fontSize={20} color={Colors.black}>
                여포낭종, 황체낭종, 난포막 황체화 낭종 등
              </Typo.Pretendard_Medium>

              <Common.SizedBoxH height={11} />

              <Typo.Pretendard_Regular width={570} textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray200}>
                대부분 젊은 사람에게서 발생하는 낭종입니다.
                <br />
                배란 과정에서 장애가 있을 시 발생하며, 자연 소실 되는 경우가 많습니다.
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>
          </Common.FlexRow>

          <Common.SizedBoxH height={110} />

          <Common.FlexRow width={1160} alignItems="flex-start" justifyContent="space-between">
            <Common.FlexColumn>
              <Styled.Content1Title>
                <Typo.Pretendard_Regular fontSize={24} color={Colors.gray300}>
                  양성 난소 낭종
                </Typo.Pretendard_Regular>
              </Styled.Content1Title>

              <Common.SizedBoxH height={24} />

              <Typo.Pretendard_Medium textAlign="left" fontSize={20} color={Colors.black}>
                기형종, 장액성 또는 점액성 낭종 등
              </Typo.Pretendard_Medium>

              <Common.SizedBoxH height={11} />

              <Typo.Pretendard_Regular width={570} textAlign="left" fontSize={20} lineHeight={30} color={Colors.gray200}>
                원인이 뚜렷하게 밝혀지지 않은 낭종입니다.
                <br />
                보통 4cm 이상 일경우 난소 꼬임을 유발 할 수 있으며
                <br />
                주기적인 통증이 있을시 내원 하여야 합니다.
                <br />
                대부분 난소의 혹은 생명에 지장은 없지만 크기가 클 경우 수술 치료가 필요할 수 있습니다.
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>
            <Common.SizedImage src={TypeImage1} width={531} height={400} />
          </Common.FlexRow>
        </Styled.Content1>

        <Styled.Content2>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            난소혹 있을 때 흔하게 나타나는 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={63} />

          <Common.FlexColumn width={1160} alignItems="stretch">
            <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
              01
            </Typo.BodoniMT_BoldItalic>
            <Common.SizedBox width={1160} height={2}>
              <Common.Fill color={Colors.gray200} />
            </Common.SizedBox>

            <Common.FlexRow>
              <Common.SizedBoxW width={80} />
              <Common.FlexColumn>
                <Common.SizedBoxH height={44} />
                <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={20} color={Colors.gray200}>
                  난소혹을 제때 치료하지 않고 방치하면 불임, 난임 등을 초래할 수 있습니다.
                  <br />
                  아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.
                </Typo.Pretendard_Regular>
                <Common.SizedBoxH height={55} />
                <Common.FlexRow alignItems="center">
                  <Styled.SymptomBalloon>
                    <Typo.Pretendard_Regular fontSize={20} color={'#665E4B'}>
                      증상
                    </Typo.Pretendard_Regular>
                  </Styled.SymptomBalloon>

                  <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={19} height={26} />

                  <Common.SizedBoxW width={20} />

                  <Styled.SymptomContainer>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        1.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        생리주기 불규칙/무월경
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        2.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        복부 팽만 및 불편감, 복통, 복부 압박 증상
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        3.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        배변통 / 성교통 / 골반통 / 월경통
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                    <Common.FlexRow height={40} alignItems="center">
                      <Typo.Abril fontSize={20} color="#665E4B">
                        4.
                      </Typo.Abril>
                      <Common.SizedBoxW width={14} />
                      <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
                        소화불량
                      </Typo.Pretendard_Regular>
                    </Common.FlexRow>
                  </Styled.SymptomContainer>
                </Common.FlexRow>
              </Common.FlexColumn>
            </Common.FlexRow>
          </Common.FlexColumn>
          <Common.SizedBoxH height={103} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            난소혹 검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={63} />

          <Common.FlexRow justifyContent="center" alignItems="center">
            {HowToCheckDatas.map((e, i) => (
              <>
                <Common.SizedBoxW width={10} key={`${e.title}0`} />
                <Styled.CheckUpContainer backgroundImage={e.image} key={`${e.title}1`}>
                  <Typo.Pretendard_Medium fontSize={30} color={Colors.white}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                </Styled.CheckUpContainer>
                <Common.SizedBoxW width={10} key={`${e.title}2`} />
              </>
            ))}
          </Common.FlexRow>
        </Styled.Content2>
      </Styled.Container>
      <Footer />
    </>
  );
}

export default Disease3;
