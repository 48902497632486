import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './checkup0.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import Banner from 'img/photos/checkup/0/banner.png';

import ProgramIcon0 from 'img/photos/checkup/0/programIcons/0.png';
import ProgramIcon1 from 'img/photos/checkup/0/programIcons/1.png';
import ProgramIcon2 from 'img/photos/checkup/0/programIcons/2.png';
import ProgramIcon3 from 'img/photos/checkup/0/programIcons/3.png';

const ProgramCardDatas = [
  {
    icon: ProgramIcon0,
    content: '임신과 연관된 위험도 평가와 상담',
  },
  {
    icon: ProgramIcon1,
    content: '임신 전 검진',
  },
  {
    icon: ProgramIcon2,
    content: '엽산제의 적절한 공급',
  },
  {
    icon: ProgramIcon3,
    content: '예방접종',
  },
];

import ProgramImage0 from 'img/photos/checkup/0/programImages/0.jpg';
import ProgramImage1 from 'img/photos/checkup/0/programImages/1.jpg';
import ProgramImage2 from 'img/photos/checkup/0/programImages/2.png';

const ProgramStepDatas = [
  {
    title: '검사 전 상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage0,
  },
  {
    title: '검사',
    content: '이러한 자료를 바탕으로 환자분 개개인에\n맞추어 필요한 예비부부 검사 프로그램을\n만들어 제시 해드립니다. ',
    image: ProgramImage1,
  },
  {
    title: '검사 후 결과상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage2,
  },
];

import StepBarImage from 'img/photos/checkup/0/bar.png';

const CheckUpTypeDatas = [
  '혈당 검사',
  '갑상선 검사',
  '풍진항체 검사',
  '간염항체 검사(A, B, C형)',
  '매독/에이즈 검사',
  '빈혈 검사',
  '혈액형 검사',
  '간기능 검사',
  '신장 검사',
  'VITD 검사',
  '소변 검사',
  '성병 검사(STD)',
  '자궁경부세포검사',
  '인유두종바이러스검사',
  '난소나이검사(AMH)',
  '여성호르몬 검사',
  '암 표지자 검사(CA 125, HE4)',
];

function CheckUp0(): ReactElement {
  const menuIndex = 3;
  const subIndex = 0;

  return (
    <>
      <Header menuIndex={menuIndex} subMenuIndex={subIndex} />

      <Styled.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Styled.Content0>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Obstetrics & Gynecology
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            예비부부검진
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={12} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            성경험과 임신 경험의 유무에 따라 필요한 건강검진을 선택하여 진행합니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={89} />

          <Common.FlexRow>
            <Common.SizedImage src={Banner} width={580} height={264} />
            <Common.SizedBox width={580} height={264}>
              <Common.Fill color={Colors.gold200}>
                <Common.FlexRow width={580} height={264} alignItems="center" justifyContent="center">
                  <Typo.Pretendard_Regular lineHeight={30} width={460} textAlign="left" fontSize={20} color={Colors.gray300}>
                    미혼여성 혹은 결혼을 앞둔 여성을 위한 건강검진으로 여성자신의 건강과 임신에 영향을 줄 수 있는 위험요인을 찾아내고,
                    치료하여 미리 예방하는 합니다. 대부분 표준화된 지침이 없이 임신 후에 시작하는 산전진찰 검사항목을 바탕으로 검사
                    프로그램을 운영하고 있습니다.
                  </Typo.Pretendard_Regular>
                </Common.FlexRow>
              </Common.Fill>
            </Common.SizedBox>
          </Common.FlexRow>

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            아라산부인과 예비부부검진 프로그램
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={9} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray300}>
            아라산부인과에서 시행하고 있는 예비부부검진 프로그램을 소개해드리겠습니다.
          </Typo.Pretendard_Regular>
        </Styled.Content0>

        <Styled.Content1>
          <Common.FlexRow alignItems="center" width={1160} justifyContent="space-between">
            {ProgramCardDatas.map((e, i) => (
              <Styled.ProgramCard key={i}>
                <Styled.ProgramCardIconContainer>
                  <Common.SizedImage src={e.icon} />
                </Styled.ProgramCardIconContainer>
                <Styled.ProgramCardViewport>
                  <Typo.Pretendard_Regular fontSize={20} color={Colors.gray300} lineHeight={32}>
                    {e.content}
                  </Typo.Pretendard_Regular>
                </Styled.ProgramCardViewport>
              </Styled.ProgramCard>
            ))}
          </Common.FlexRow>
        </Styled.Content1>

        <Styled.Content2>
          <Common.FlexRow width={1160} alignItems="center" justifyContent="space-between">
            {ProgramStepDatas.map((e, i) => (
              <Styled.ProgramStepCard>
                <Common.SizedImage src={e.image} width={373} height={191} objectFit="cover" />
                <Common.SizedBox width={373} height={220}>
                  <Common.Fill color={Colors.white}>
                    <Common.FlexColumn alignItems="center">
                      <Common.SizedBoxH height={25} />
                      <Common.FlexRow>
                        <Typo.BodoniMT_BoldItalic fontSize={20} color={Colors.gray300}>
                          STEP 0{i + 1}
                        </Typo.BodoniMT_BoldItalic>
                        <Typo.Pretendard_Medium fontSize={20} color={Colors.gray300}>
                          {e.title}
                        </Typo.Pretendard_Medium>
                      </Common.FlexRow>
                      <Common.SizedBoxH height={10} />
                      <Typo.Pretendard_Regular fontSize={16} color={Colors.gray200} lineHeight={24}>
                        {e.content}
                      </Typo.Pretendard_Regular>
                    </Common.FlexColumn>
                  </Common.Fill>
                </Common.SizedBox>
              </Styled.ProgramStepCard>
            ))}
          </Common.FlexRow>

          <Common.SizedBoxH height={61} />

          <Common.FlexRow width={1160} alignItems="center" justifyContent="center">
            <Common.FlexColumn justifyContent="center">
              <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                일반적인 건강검진
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular fontSize={16} color={Colors.black}>
                (혈액, 소변검사)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.SizedBoxW width={83} />

            <Common.FlexColumn justifyContent="center">
              <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                항체검사
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular fontSize={16} color={Colors.black}>
                (혈액검사)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.SizedBoxW width={116} />

            <Common.FlexColumn justifyContent="center">
              <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
                성병종합검사
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular fontSize={16} color={Colors.black}>
                (냉검사)
              </Typo.Pretendard_Regular>
            </Common.FlexColumn>

            <Common.SizedBoxW width={102} />

            <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
              여성암검진
            </Typo.Pretendard_Regular>

            <Common.SizedBoxW width={87} />

            <Typo.Pretendard_Regular fontSize={20} color={Colors.black}>
              자궁 초음파 검사
            </Typo.Pretendard_Regular>

            <Common.SizedBoxW width={5} />
          </Common.FlexRow>
          <Common.SizedBoxH height={7} />
          <Common.SizedImage src={StepBarImage} width={1160} height={25} />

          <Styled.Content2Background />
        </Styled.Content2>

        <Styled.Content3>
          <Typo.Pretendard_Medium fontSize={20} color={'#dddddd'}>
            Test
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={50} color={Colors.black}>
            검사항목
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={100} />

          <Common.FlexRow alignItems="center" justifyContent="space-between" width={1160}>
            <Styled.TestTypeTitle>
              <Common.SizedBoxH height={79} />
              <Typo.Pretendard_Regular height={40} fontSize={20} color={Colors.gray300}>
                웨딩 검진
              </Typo.Pretendard_Regular>
              <Common.SizedBoxH height={5} />
              <Typo.Pretendard_Regular lineHeight={25} fontSize={16} color={Colors.gray300}>
                결혼을 앞둔 여성이
                <br />
                체크하면 좋을
              </Typo.Pretendard_Regular>
            </Styled.TestTypeTitle>

            <Styled.TestTypeContents>
              {CheckUpTypeDatas.map((e) => (
                <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={20}>
                  · {e}
                </Typo.Pretendard_Regular>
              ))}
            </Styled.TestTypeContents>
          </Common.FlexRow>
        </Styled.Content3>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default CheckUp0;
