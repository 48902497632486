import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

import Mask from 'img/common/TitleMask.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  height: 500px;

  position: relative;

  background-color: ${Colors.gold200};

  overflow: hidden;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 148px;
  left: calc(50% + 113px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ImageContainer = styled.img`
  position: absolute;

  bottom: 0;
  right: calc(50% - 87px);

  -webkit-mask-image: url(${Mask});
  mask-image: url(${Mask});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  object-fit: contain;
`;
